import { useApolloClient } from "@apollo/client";
import {
  DeleteRawViewDocument,
  EltSyncsDocument,
  GetRawViewsDocument,
  useImportDwDirectoryMutation,
  useRemoveEltSyncMutation,
} from "@/apollo/types";
import confirm from "@/components/elements/Confirm";
import LoadingSpinner from "@/components/elements/LoadingSpinner";
import MenuItem from "@/components/elements/MenuItem";
import TableMenu from "@/components/modules/TableMenu";
import { useViewDataSourceSlideOver } from "@/components/modules/view-data-source-slideover";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useMixpanel } from "@/monitoring/mixpanel";
import { useCheckMetricAvailable } from "@/pages/Metrics/components/useTemplateConfig";
import { useCoreMetricsOpen } from "@/pages/Metrics/CoreMetricsDrawer";
import { useToast } from "@/providers/ToastProvider";
import React, { useState } from "react";

import { useOpenLineageModal } from "../../lineage/RawDataLineageModal";
import { EltStreamItemType, ViewItemType } from "../SidebarFactory";
import { useCurrentAccount } from "@/providers/account";

export const RawFolderOptions: React.FC<{
  id: string;
  folderName: string;
  children: (ViewItemType | EltStreamItemType)[];
  syncId?: string;
  integrationId?: string;
}> = (props) => {
  const account = useCurrentAccount();
  const [deleting, setDeleting] = useState(false);
  const toast = useToast();
  const mixpanel = useMixpanel();

  const showLineageModal = useOpenLineageModal();

  const client = useApolloClient();
  const { onOpen: viewSync } = useViewDataSourceSlideOver();

  const [reImportDirectory, { loading: importing }] =
    useImportDwDirectoryMutation({
      variables: {
        input: {
          connectionId: account.dataWarehouseConnectionId ?? "",
          schema: props.id,
          tables: props.children
            .filter(isItemImported)
            .map((child) => child.id),
        },
      },
      onCompleted(data) {
        if (data.importDWDirectory.every((item) => !item.error)) {
          toast(
            `Re-imported ${props.folderName}`,
            `Successfully re-imported ${props.folderName} folder.`,
            "success",
          );
          return;
        }

        const itemsWithError = data.importDWDirectory.filter(
          (item) => item.error,
        );
        toast(
          `Error re-importing ${itemsWithError.length} table${itemsWithError.length > 1 ? "s" : ""}`,
          itemsWithError[0].error ?? "",
          "error",
        );
      },
      onError: (error) => {
        toast(`Error re-importing ${props.folderName}`, error.message, "error");
      },
      refetchQueries: [{ query: GetRawViewsDocument }],
    });

  const deleteReference = (viewId: string) => {
    return client.mutate({
      mutation: DeleteRawViewDocument,
      variables: { viewId },
    });
  };

  const [deleteEltSync, deleteEltSyncMutation] = useRemoveEltSyncMutation({
    refetchQueries: [
      {
        query: GetRawViewsDocument,
      },
      { query: EltSyncsDocument },
    ],
    awaitRefetchQueries: true,
    onCompleted: () => {
      toast(
        "Deleted data source",
        "Data source deleted successfully",
        "success",
      );
    },
    onError: (error) => {
      toast("Error deleting folder", error.message, "error");
    },
  });

  const handleDeleteAllReferencesInFolder = async () => {
    setDeleting(true);
    const deleteResults = await Promise.allSettled(
      props.children.map((child) => {
        if (child.itemType === "view") {
          return deleteReference(child.rawView.viewId);
        } else return null;
      }),
    );

    try {
      await client.refetchQueries({
        include: [GetRawViewsDocument],
      });
    } catch (error) {
      setDeleting(false);
      toast(
        "Error deleting folder",
        "An error occurred after attempting to delete folder.",
        "error",
      );
      return;
    }

    setDeleting(false);

    const successful = deleteResults.filter((r) => r.status === "fulfilled");
    const failed = deleteResults.filter((r) => r.status === "rejected");

    if (successful.length === 0) {
      toast(`Failed to delete folder`, "All items failed to delete.", "error");
      return;
    }
    if (failed.length > 0) {
      toast(
        `Failed to delete some items`,
        `Successfully deleted ${successful.length} items. Failed to delete ${failed.length} items.`,
        "warning",
      );
      return;
    }
    toast(
      `Deleted ${props.folderName}`,
      `Successfully deleted ${successful.length} items.`,
      "success",
    );
  };
  const { coreMetricsEnabled } = useFlags();
  const coreMetricsAvailable = useCheckMetricAvailable(props.integrationId);
  const [, setCoreMetricsOpen] = useCoreMetricsOpen();

  if (deleting) return <LoadingSpinner className="h-4 w-4" />;
  return (
    <>
      <TableMenu size="sm">
        <>
          {props.syncId && (
            <MenuItem
              text="View sync"
              size="sm"
              onClick={() => {
                if (!props.syncId) return;
                mixpanel.track("View Data Source Sync Clicked", {
                  source: "editor sidebar",
                });
                viewSync({
                  syncId: props.syncId,
                });
              }}
            />
          )}
          {coreMetricsEnabled && coreMetricsAvailable && (
            <MenuItem
              text="View Core Metrics"
              size="sm"
              onClick={() => {
                setCoreMetricsOpen(true);
              }}
            />
          )}

          {props.syncId && (
            <MenuItem
              text="View data lineage"
              size="sm"
              onClick={() => {
                if (!props.syncId) return;

                showLineageModal({
                  focusedId: props.syncId,
                  title: props.folderName,
                });
              }}
            />
          )}
          {props.children.some(isItemImported) && (
            <MenuItem
              text="Re-import data folder"
              size="sm"
              disabled={importing}
              onClick={async () => {
                if (!importing) {
                  await reImportDirectory();
                }
              }}
            />
          )}
          {!props.syncId && (
            <MenuItem
              text="Delete data folder"
              size="sm"
              disabled={deleting}
              onClick={async () => {
                if (deleting) return;

                const confirmed = await confirm({
                  type: "danger",
                  title: "Delete data folder",
                  message: `This will remove ${props.children.length} table${
                    props.children.length !== 1 ? "s" : ""
                  }. This can affect models using this data.`,
                });
                if (!confirmed) return;
                await handleDeleteAllReferencesInFolder();
              }}
            />
          )}
          {props.syncId && (
            <MenuItem
              text="Delete data source" //If has sync - delete sync!
              size="sm"
              onClick={async () => {
                if (deleteEltSyncMutation.loading) return;

                const confirmed = await confirm({
                  type: "danger",
                  title: "Delete data source",
                  message: `This will remove the sync and ${
                    props.children.length
                  } table${
                    props.children.length !== 1 ? "s" : ""
                  }. This can affect models using this data.`,
                });
                if (!confirmed) return;

                await deleteEltSync({
                  variables: {
                    eltSyncId: props.syncId!,
                    removeDataReferences: true,
                  },
                });
              }}
            />
          )}
        </>
      </TableMenu>
    </>
  );
};

function isItemImported(item: ViewItemType | EltStreamItemType) {
  return item.itemType === "view" && item.isViewImported;
}
