import { startCase } from "lodash";

import { FullSyncTrigger, OperationMode, SyncJobStatus } from "@/apollo/types";
import { isFullSync } from "@/entities/sync-history";

export function getSyncJobColorProps<
  T extends {
    status?: SyncJobStatus;
    operationMode?: OperationMode;
    fullSyncTrigger?: FullSyncTrigger;
  },
>(params: T) {
  switch (params.status) {
    case SyncJobStatus.Scheduled:
    case SyncJobStatus.Running:
      if (isFullSync(params.fullSyncTrigger)) {
        return { fill: "#60a5fa", stroke: "#eda92b", strokeWidth: 2 };
      }
      return { fill: "#60a5fa" };
    case SyncJobStatus.Completed: {
      if (isFullSync(params.fullSyncTrigger)) {
        return { fill: "#eda92b" };
      }
      return { fill: "#2ba67f" };
    }
    case SyncJobStatus.Failed:
      return { fill: "#f87171" };
    case SyncJobStatus.Retrying:
      return { fill: "#facc15" };
    case SyncJobStatus.Delayed:
      return { fill: "#d4d4d8" };
    default:
      return { fill: "#d1d5db" };
  }
}

export function formatSyncStatus(status: SyncJobStatus) {
  return startCase(status.toLowerCase());
}
