import { uniqBy } from "lodash";
import { useMemo } from "react";

import {
  AvailableIntegrationFragment,
  useAvailableIntegrationsQuery,
} from "@/apollo/types";
import { Ability, IntegrationType, useIntegrationsMap } from "@/integrations";

import { useComingSoonConnectorOptions } from "../connector-options/useBetaConnectorOptions";
import { useCommunityCustomConnectorOptions } from "../connector-options/useCommunityCustomConnectorOptions";
import {
  createConnectorOption,
  useGetIntegrationFlags,
} from "../connector-options/useCreateConnectorOptions";

export function useRecommendedConnectorOptions(props: {
  dwIntegration: IntegrationType;
  integrationIds: string[];
  abilityFilter?: Ability;
}) {
  const communityOptions = useCommunityCustomConnectorOptions();
  const betaOptions = useComingSoonConnectorOptions(props.dwIntegration);

  const integrationsRegistry = useIntegrationsMap(props.abilityFilter);
  const getIntegrationFlags = useGetIntegrationFlags();

  const { data } = useAvailableIntegrationsQuery();
  const integrationMetadataMap = useMemo(() => {
    const integrationMetadataMap = (data?.availableIntegrations ?? []).reduce(
      (acc, integration) => {
        acc[integration.id] = integration;
        return acc;
      },
      {} as Record<string, AvailableIntegrationFragment>,
    );
    return integrationMetadataMap;
  }, [data]);

  const options = useMemo(() => {
    const options = props.integrationIds.reduce(
      (acc, integrationId) => {
        const integration = integrationsRegistry.get(integrationId);
        if (integration) {
          const flags = getIntegrationFlags(integration.id);
          const option = createConnectorOption(
            {
              ...integration,
              metadata: integrationMetadataMap[integrationId],
            },
            flags,
          );
          acc.push(option);
        } else {
          const communityOption = communityOptions.find(
            (option) => option.value === integrationId,
          );
          if (communityOption) {
            acc.push(communityOption);
          }
          const betaOption = betaOptions.find(
            (option) => option.value === integrationId,
          );
          if (betaOption) {
            acc.push(betaOption);
          }
        }
        return acc;
      },
      [] as ReturnType<typeof createConnectorOption>[],
    );
    return uniqBy(options, (option) => option.value);
  }, [
    betaOptions,
    communityOptions,
    getIntegrationFlags,
    integrationsRegistry,
    props.integrationIds,
    integrationMetadataMap,
  ]);
  return options;
}
