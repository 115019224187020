import { startCase } from "lodash";

import { FullSyncTrigger, OperationMode } from "@/apollo/types";

export function formatOperationMode(
  mode: OperationMode,
  fullSyncTrigger?: FullSyncTrigger | null,
) {
  if (fullSyncTrigger === FullSyncTrigger.Lookback) {
    return startCase(OperationMode.Incremental.toLowerCase());
  }
  return startCase(mode.toLowerCase());
}

export function formatFullSyncTrigger(trigger: FullSyncTrigger) {
  switch (trigger) {
    case FullSyncTrigger.AlwaysFull: {
      return "Always";
    }
    case FullSyncTrigger.Error: {
      return "Error";
    }
    case FullSyncTrigger.InitialSync: {
      return "Initial sync";
    }
    case FullSyncTrigger.Lookback: {
      return "Lookback";
    }
    case FullSyncTrigger.Midnight: {
      return "At midnight (UTC)";
    }
    case FullSyncTrigger.Migration: {
      return "Data warehouse migration";
    }
    case FullSyncTrigger.SchemaChange: {
      return "Schema change";
    }
    case FullSyncTrigger.User: {
      return "Initiated by user";
    }
    default: {
      return "";
    }
  }
}
