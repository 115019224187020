import { ComponentProps, useEffect } from "react";

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
} from "@/components/elements/Modal";
import { WELD_EXISTING_CUSTOMER_CALL_URL } from "@/constants/external-urls/hubspot";
import { WELD_PRICING_URL } from "@/constants/external-urls/weld-website";
import { useMixpanel } from "@/monitoring/mixpanel";
import { useCurrentAccount } from "@/providers/account";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/16/solid";

export function CoreProPlanDiscontinuationDialog(
  props: ComponentProps<typeof Modal>,
) {
  const mixpanel = useMixpanel();

  useEffect(() => {
    if (props.isOpen) {
      mixpanel.track("Core_Pro Plan Discontinuation Dialog - Opened");
    }
  }, [props.isOpen, mixpanel]);

  const account = useCurrentAccount();

  return (
    <Modal {...props} centerOnScreen={false}>
      <ModalHeader>Important Update: Core/Pro Plan Discontinuation</ModalHeader>
      <ModalCloseButton />
      <ModalBody className="pb-5">
        <div className="space-y-5">
          <p>Dear {account.name || account.slug}</p>
          <p>Thank you for being a valued Weld customer!</p>
          <p>
            We want to inform you that the{" "}
            <strong>Core/Pro Plan will be discontinued on May 1st, 2025</strong>
            . To ensure uninterrupted service, we encourage you to transition to
            one of our updated plans before this date. You can explore the
            available options on our{" "}
            <a
              href={WELD_PRICING_URL}
              className="inline-flex items-center text-blue-600 underline"
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                mixpanel.track(
                  "Core_Pro Plan Discontinuation Dialog - Pricing Page Clicked",
                );
              }}
            >
              pricing page
              <ArrowTopRightOnSquareIcon className="ml-1 h-4 w-4" />
            </a>
            .
          </p>
          <p>
            Our new plans offer{" "}
            <strong>
              fixed, predictable pricing that is no longer tied to data volume
            </strong>
            . Instead, we’ve introduced a <strong>fair usage policy</strong>{" "}
            designed to accommodate most needs, providing greater transparency
            and flexibility.
          </p>
          <p>
            This change allows us to continue improving our platform and
            delivering the best possible support for all our customers.
          </p>

          <p>
            Our sales team is happy to assist you with any questions or help you
            choose the best plan for your needs.{" "}
            <a
              href={WELD_EXISTING_CUSTOMER_CALL_URL}
              className="inline-flex items-center text-blue-600 underline"
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                mixpanel.track(
                  "Core_Pro Plan Discontinuation Dialog - Book a Call Clicked",
                );
              }}
            >
              Feel free to book a meeting here
              <ArrowTopRightOnSquareIcon className="ml-1 h-4 w-4" />
            </a>
            .
          </p>

          <p>Thank you for your continued support!</p>

          <p>
            <strong>— The Weld Team</strong>
          </p>
        </div>
      </ModalBody>
    </Modal>
  );
}
