import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useEffect, useRef, useState } from "react";
import { LocationGenerics } from "routes";

import {
  FindSyncHistoryQuery,
  FindSyncHistoryResponse,
  SyncJobStatus,
  useFindSyncHistoryQuery,
} from "@/apollo/types";
import { SecondaryButton } from "@/components/elements/Button";
import { Empty } from "@/components/elements/DataBox";
import Tooltip from "@/components/elements/Tooltip";
import classNames from "@/helpers/classNames";
import { dateTimeFormat } from "@/helpers/dateFormat";
import useLoadingManager from "@/hooks/useLoadingManager";
import { formatRowCount } from "@/shared/formatters";
import { useMatch } from "@tanstack/react-location";

import { ReverseEltJobLoggingModalContainer } from "./ReverseEltJobLogsModal";

dayjs.extend(relativeTime);

export function SyncHistory(props: { pollTime: number }) {
  const {
    params: { syncId },
  } = useMatch<LocationGenerics>();

  const {
    loading,
    data: { findReverseEltSyncHistory = [] } = {},
    error,
    stopPolling,
    startPolling,
  } = useFindSyncHistoryQuery({
    variables: { syncId },
    skip: !syncId,
  });

  const [clickedJob, setClickedJob] = useState<
    (typeof findReverseEltSyncHistory)[0] | null
  >(null);

  const EmptyHistory = () => {
    return <Empty>Your sync history will appear here.</Empty>;
  };

  const hasFocus = useRef(true);
  useEffect(() => {
    const blurListener = () => {
      hasFocus.current = false;
      stopPolling();
    };
    const focusListener = () => {
      if (hasFocus.current) return;
      hasFocus.current = true;
      startPolling(props.pollTime);
    };
    window.addEventListener("blur", blurListener);
    window.addEventListener("focus", focusListener);
    return () => {
      window.removeEventListener("blur", blurListener);
      window.removeEventListener("focus", focusListener);
    };
  }, [props.pollTime, startPolling, stopPolling]);

  useEffect(() => {
    if (error) {
      stopPolling();
    }
  }, [error, stopPolling]);

  useEffect(() => {
    startPolling(props.pollTime);
    return () => stopPolling();
  }, [startPolling, stopPolling, props.pollTime]);

  return useLoadingManager({ loading, message: "Loading history" }, () => {
    if (!findReverseEltSyncHistory.length) return <EmptyHistory />;
    return (
      <div className="space-y-5">
        {findReverseEltSyncHistory.map((row, i) => {
          return (
            <SyncHistoryItem
              row={row}
              key={row.jobId ?? i}
              onClickOpenLogs={() => setClickedJob(row ?? null)}
              isLatest={i === 0}
            />
          );
        })}
        {clickedJob && (
          <ReverseEltJobLoggingModalContainer
            parentJob={clickedJob}
            onClose={() => {
              setClickedJob(null);
            }}
          />
        )}
      </div>
    );
  });
}

const StatusIcon = {
  [SyncJobStatus.Completed]: (
    <svg className="h-5 w-5 fill-current text-green-500" viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
        clipRule="evenodd"
      />
    </svg>
  ),
  [SyncJobStatus.Failed]: (
    <svg className="h-5 w-5 fill-current text-red-500" viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
        clipRule="evenodd"
      />
    </svg>
  ),
  [SyncJobStatus.Running]: (
    <svg
      className="h-5 w-5 animate-pulse fill-current text-blue-500"
      viewBox="0 0 20 20"
    >
      <path
        fillRule="evenodd"
        d="M9.504 1.132a1 1 0 01.992 0l1.75 1a1 1 0 11-.992 1.736L10 3.152l-1.254.716a1 1 0 11-.992-1.736l1.75-1zM5.618 4.504a1 1 0 01-.372 1.364L5.016 6l.23.132a1 1 0 11-.992 1.736L4 7.723V8a1 1 0 01-2 0V6a.996.996 0 01.52-.878l1.734-.99a1 1 0 011.364.372zm8.764 0a1 1 0 011.364-.372l1.733.99A1.002 1.002 0 0118 6v2a1 1 0 11-2 0v-.277l-.254.145a1 1 0 11-.992-1.736l.23-.132-.23-.132a1 1 0 01-.372-1.364zm-7 4a1 1 0 011.364-.372L10 8.848l1.254-.716a1 1 0 11.992 1.736L11 10.58V12a1 1 0 11-2 0v-1.42l-1.246-.712a1 1 0 01-.372-1.364zM3 11a1 1 0 011 1v1.42l1.246.712a1 1 0 11-.992 1.736l-1.75-1A1 1 0 012 14v-2a1 1 0 011-1zm14 0a1 1 0 011 1v2a1 1 0 01-.504.868l-1.75 1a1 1 0 11-.992-1.736L16 13.42V12a1 1 0 011-1zm-9.618 5.504a1 1 0 011.364-.372l.254.145V16a1 1 0 112 0v.277l.254-.145a1 1 0 11.992 1.736l-1.735.992a.995.995 0 01-1.022 0l-1.735-.992a1 1 0 01-.372-1.364z"
        clipRule="evenodd"
      />
    </svg>
  ),
  [SyncJobStatus.Scheduled]: (
    <svg className="h-5 w-5 fill-current text-blue-500" viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
        clipRule="evenodd"
      />
    </svg>
  ),
};

const StatusImage = ({ status }: { status: keyof typeof StatusIcon }) => (
  <div className="flex shrink-0 justify-center">{StatusIcon[status]}</div>
);

const statusText: Record<string, string> = {
  COMPLETED: "Completed",
  SCHEDULED: "Scheduled",
  RUNNING: "Running",
  FAILED: "Failed",
};

const WhichHistory = (props: {
  syncHistoryItem: FindSyncHistoryQuery["findReverseEltSyncHistory"][0];
  isLatest: boolean;
}) => {
  const { syncHistoryItem, isLatest } = props;

  return syncHistoryItem && syncHistoryItem.totalRecords != null ? (
    <div className="flex flex-row space-x-1.5 text-xs text-gray-400">
      <div>{formatRowCount(syncHistoryItem.totalRecords ?? 0)} total rows</div>
      <div>·</div>
      <div>{formatRowCount(syncHistoryItem.newRecords ?? 0)} new rows</div>
      <div>·</div>
      <div>
        {formatRowCount(syncHistoryItem.syncedRecords ?? 0)} synced rows
      </div>
    </div>
  ) : (
    <div className="flex flex-row space-x-2 text-xs text-gray-400">
      <div>{isLatest ? "Waiting for job to start ..." : "No job found."}</div>
    </div>
  );
};

export type SyncHistoryItemProps = {
  row: FindSyncHistoryResponse;
  onClickOpenLogs: () => void;
  isLatest: boolean;
};

export function SyncHistoryItem(props: SyncHistoryItemProps) {
  return (
    <div
      className={classNames(
        "hover:bg-black-300 flex items-center space-x-4 hover:cursor-pointer",
      )}
    >
      <StatusImage
        status={(props.row.status as keyof typeof StatusIcon) || ""}
      />
      <div className="grow space-y-2">
        <Tooltip content={"Job id: " + props.row.jobId}>
          <div className="text-sm font-bold leading-none text-gray-800 dark:text-white">
            {statusText[props.row.status || ""]}
          </div>
        </Tooltip>
        <WhichHistory syncHistoryItem={props.row} isLatest={props.isLatest} />
      </div>
      <SecondaryButton onClick={props.onClickOpenLogs}>Logs</SecondaryButton>

      <Tooltip
        content={dayjs(props.row.startedAt || "").format(dateTimeFormat)}
      >
        <div className="shrink-0 text-sm text-gray-400">
          {dayjs(props.row.startedAt || "").fromNow()}
        </div>
      </Tooltip>
    </div>
  );
}
