import { useEltSyncsQuery } from "@/apollo/types";
import Content from "@/components/elements/layout/Content";
import EltSyncsTable from "@/pages/EltSyncs/modules/EltSyncsTable";

import MainTabsLayout from "./MainTabsLayout";
import { EltSyncsEmptyState } from "./modules/EltSyncsEmptyState";

export default function EltSyncsPage() {
  const { data, loading } = useEltSyncsQuery();

  if (loading) return <div />;
  if (data?.eltSyncs.length === 0) {
    return (
      <Content className="overflow-hidden">
        <EltSyncsEmptyState />
      </Content>
    );
  }
  return (
    <MainTabsLayout>
      <EltSyncsTable syncs={data?.eltSyncs ?? []} />
    </MainTabsLayout>
  );
}
