import { PropsWithChildren, forwardRef } from "react";

import classNames from "@/helpers/classNames";

export enum BadgeVariant {
  Error = "error",
  Info = "info",
  Default = "default",
  DefaultSubtle = "default-subtle",
  Success = "success",
  Inactive = "inactive",
}

export const BadgeStatus: Record<string, BadgeVariant> = {
  COMPLETED: BadgeVariant.Success,
  FAILED: BadgeVariant.Error,
  RUNNING: BadgeVariant.Default,
  SCHEDULED: BadgeVariant.Default,
  QUARANTINED: BadgeVariant.Error,
  STARTING: BadgeVariant.DefaultSubtle,
  STOPPED: BadgeVariant.Info,
  DELAYED: BadgeVariant.Info,
  WARNING: BadgeVariant.Info,
  RETRYING: BadgeVariant.Info,
};

const Badge = forwardRef<
  HTMLDivElement,
  PropsWithChildren<{
    className?: string;
    variant: BadgeVariant;
    size?: "sm" | "md";
  }>
>(({ variant, ...props }, ref) => {
  return (
    <div
      ref={ref}
      {...props}
      className={classNames(
        variant === BadgeVariant.Error &&
          "border border-red-200 bg-red-50 text-red-500 dark:border-red-500/75 dark:bg-red-600/10",
        variant === BadgeVariant.Default &&
          "border border-blue-200 bg-blue-50 text-blue-500 dark:border-blue-500/75 dark:bg-blue-500/10",
        variant === BadgeVariant.DefaultSubtle &&
          "border border-transparent bg-blue-50 font-medium text-blue-500 opacity-85 dark:bg-blue-500/10",
        variant === BadgeVariant.Info &&
          "border border-yellow-200 bg-yellow-50 text-yellow-600 dark:border-yellow-600 dark:bg-yellow-500/10",
        variant === BadgeVariant.Success &&
          "border border-green-200 bg-green-50 text-green-500 dark:border-green-700 dark:bg-green-500/10",
        variant === BadgeVariant.Inactive &&
          "border border-gray-200 bg-gray-50 text-gray-400 dark:border-gray-500 dark:bg-gray-500/20",
        "inline-flex cursor-default items-center rounded-sm px-2 py-0.5 text-1xs",
        "whitespace-nowrap font-semibold uppercase leading-normal tracking-wider",
        props.size === "sm" ? "text-2xs" : "",
        props.className,
      )}
    >
      {props.children}
    </div>
  );
});

export default Badge;
