import dayjs from "dayjs";
import { useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useMemo } from "react";

import { PlanName } from "@/apollo/types";
import { Button } from "@/components/elements/Button";
import {
  WorkspaceAlert,
  WorkspaceAlertResolver,
} from "@/components/modules/AppBanners/useWorkspaceAlerts";
import { useCurrentPlan } from "@/features/subscription";
import { useDisclosure } from "@/hooks/useDisclosure";
import { useMixpanel } from "@/monitoring/mixpanel";
import { createStorageKey } from "@/utils/storage";

import { CoreProPlanDiscontinuationDialog } from "../shared/ui/CoreProPlanDiscontinuationDialog";
import { discontinuationDate, getNextDismissedUntil } from "../shared/utils";

const bannerAtom = atomWithStorage<{
  dismissedUntil: number;
}>(createStorageKey("core-pro-plan-discontinuation-banner"), {
  dismissedUntil: 0,
});

export const useCoreProPlanDiscontinuationResolver: WorkspaceAlertResolver =
  () => {
    const weldPlan = useCurrentPlan();
    const [state, setState] = useAtom(bannerAtom);
    const { showCoreProPlanDiscontinuationAnnouncement } = useFlags();

    const alerts = useMemo<WorkspaceAlert[]>(() => {
      return [
        {
          id: "core-pro-plan-discontinuation",
          alwaysShow: true,
          status: "info",
          message: "The Core/Pro plan will be discontinued soon.",
          action: <LearnMoreButton />,
          isDismissable: true,
          onDismiss: () => {
            const nextDismissedUntil = getNextDismissedUntil(
              dayjs(),
              discontinuationDate,
            ).valueOf();
            setState({ dismissedUntil: nextDismissedUntil });
          },
        },
      ];
    }, [setState]);

    if (
      ![PlanName.Core, PlanName.Pro].includes(weldPlan.name) ||
      !showCoreProPlanDiscontinuationAnnouncement
    ) {
      return [];
    }
    if (state.dismissedUntil > Date.now()) {
      return [];
    }
    return alerts;
  };

function LearnMoreButton() {
  const disclosure = useDisclosure();
  const mixpanel = useMixpanel();
  return (
    <>
      <Button
        onClick={() => {
          disclosure.onOpen();
          mixpanel.track("Show Core_Pro Plan Discontinuation Dialog Clicked", {
            location: "workspace-banner",
          });
        }}
        colorScheme="primary"
        variant="solid"
        size="xs"
      >
        Learn more
      </Button>
      <CoreProPlanDiscontinuationDialog {...disclosure} />
    </>
  );
}
