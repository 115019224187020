import { FullSyncTrigger } from "@/apollo/types";

export const isFullSync = (
  fullSyncTrigger: FullSyncTrigger | null | undefined,
) => {
  return (
    fullSyncTrigger != null &&
    ![FullSyncTrigger.None, FullSyncTrigger.Lookback].includes(fullSyncTrigger)
  );
};
