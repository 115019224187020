import { useState } from "react";

import { InitialSyncStatusResponse } from "@/apollo/types";
import { Banner, BannerCloseButton } from "@/components/elements/Banner";
import { List } from "@/components/elements/List";
import cn from "@/helpers/classNames";
import { IntegrationLogo } from "@/integrations";
import { InformationCircleIcon } from "@heroicons/react/20/solid";
import * as HoverCard from "@radix-ui/react-hover-card";

import { InitialSyncItem } from "./InitialSyncItem";
import {
  useCompletedSyncsLastMinute,
  useInitialSyncsStatus,
} from "./useInitialSyncsStatus";

function generateBannerText(
  numCompletedSyncs: number,
  numRunningSyncs: number,
) {
  let bannerTexts: string[] = [];
  if (numCompletedSyncs > 0) {
    bannerTexts.push(
      `${numCompletedSyncs} ${
        numCompletedSyncs === 1 ? "sync" : "syncs"
      } completed`,
    );
  }
  if (numRunningSyncs > 0) {
    bannerTexts.push(
      `${
        numRunningSyncs > 1
          ? `${numRunningSyncs} Initial syncs`
          : "Initial sync"
      } in progress...`,
    );
  }
  return bannerTexts.join(" • ");
}

export function InitialSyncsProgressBanner(props: {
  syncIds: string[];
  refetchSyncs: () => void;
  dismiss: () => void;
}) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [completedSyncs, setCompletedSyncs] = useState<
    InitialSyncStatusResponse[]
  >([]);

  const recentlyCompletedSyncs = useCompletedSyncsLastMinute(completedSyncs);

  const runningSyncs = useInitialSyncsStatus(props.syncIds, {
    refreshInterval: isDialogOpen ? 15_000 : 60_000,
    onSyncsCompleted(syncs) {
      setCompletedSyncs((prev) => {
        const newSyncs = syncs.filter((sync) =>
          prev.every((s) => s.syncId !== sync.syncId),
        );
        return [...newSyncs, ...prev];
      });
      props.refetchSyncs();
    },
  });

  const uniqueIntegrationIds = Array.from(
    new Set(runningSyncs.map((sync) => sync.integrationId)),
  );

  return (
    <HoverCard.Root
      openDelay={0}
      closeDelay={200}
      open={isDialogOpen}
      onOpenChange={setIsDialogOpen}
    >
      <HoverCard.Trigger asChild>
        <div className="w-full">
          <Banner
            status="info"
            className={cn(
              "border-none bg-blue-500 py-1 text-white transition-all dark:bg-blue-600",
              {
                "bg-green-600 dark:bg-green-600":
                  recentlyCompletedSyncs.length > 0,
              },
            )}
          >
            <div className="flex cursor-default items-center justify-center gap-2">
              <div className="flex items-center">
                <InformationCircleIcon className="mr-1.5 inline h-4 w-4" />
                <span className="font-semibold">
                  {generateBannerText(
                    recentlyCompletedSyncs.length,
                    runningSyncs.length,
                  )}
                </span>
              </div>
              <div className="flex gap-1">
                {uniqueIntegrationIds.map((id) => (
                  <IntegrationLogo
                    key={id}
                    id={id}
                    className="h-4 w-4 animate-pulse"
                  />
                ))}
              </div>
            </div>
            <BannerCloseButton onClick={() => props.dismiss()} />
          </Banner>
        </div>
      </HoverCard.Trigger>
      <HoverCard.Portal>
        <HoverCard.Content
          align="center"
          sideOffset={8}
          className="z-50 max-h-none max-w-full rounded-md border bg-background shadow-md outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2"
        >
          <div className="flex max-h-[80vh] w-screen flex-col md:max-w-screen-xs">
            <List className="divide-y p-0">
              {runningSyncs.map((sync) => (
                <List.Item key={sync.syncId} className="w-full p-4">
                  <InitialSyncItem {...sync} />
                </List.Item>
              ))}
              {completedSyncs.map((sync) => (
                <List.Item key={sync.syncId} className="w-full p-4">
                  <InitialSyncItem {...sync} />
                </List.Item>
              ))}
            </List>
            <div className="flex gap-2 border-t bg-muted p-4 text-sm">
              <InformationCircleIcon className="mt-0.5 inline h-4 w-4 shrink-0 text-muted-foreground" />
              <p>
                Initial syncs take time as they process all historical data.
                Querying is unavailable until syncing is complete. Duration
                depends on data volume and source system limits.{" "}
                {/* eslint-disable-next-line */}
                <a
                  href="https://weld.app/docs/account/data-sources#initial-syncs"
                  target="_blank"
                  className="underline"
                >
                  Learn more
                </a>
              </p>
            </div>
          </div>
        </HoverCard.Content>
      </HoverCard.Portal>
    </HoverCard.Root>
  );
}
