import { useFlags } from "launchdarkly-react-client-sdk";
import { useEffect, useState } from "react";

import { useActiveInitialSyncsQuery } from "@/apollo/types";
import {
  WorkspaceAlert,
  WorkspaceAlertResolver,
} from "@/components/modules/AppBanners/useWorkspaceAlerts";

import { InitialSyncsProgressBanner } from "./InitialSyncsProgressBanner";

export const useInitialSyncsProgressBannerResolver: WorkspaceAlertResolver =
  () => {
    const [banners, setBanners] = useState<WorkspaceAlert[]>([]);

    const { showInitialSyncsProgressBanner } = useFlags();

    const { data, refetch } = useActiveInitialSyncsQuery({
      fetchPolicy: "network-only",
      skip: !showInitialSyncsProgressBanner,
    });

    useEffect(() => {
      if (!data || data.activeInitialSyncs.length === 0) {
        setBanners([]);
      } else {
        setBanners([
          {
            id: "first-sync-progress",
            alwaysShow: true,
            status: "info",
            message: "",
            isDismissable: true,
            render: ({ dismiss }) => {
              return (
                <InitialSyncsProgressBanner
                  syncIds={data.activeInitialSyncs.map((sync) => sync.id)}
                  refetchSyncs={refetch}
                  dismiss={dismiss}
                />
              );
            },
          },
        ]);
      }
    }, [data, setBanners, refetch]);

    if (!showInitialSyncsProgressBanner) {
      return [];
    }
    return banners;
  };
