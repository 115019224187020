import { useState } from "react";

import { Button } from "@/components/elements/Button";
import LoadingSpinner from "@/components/elements/LoadingSpinner";
import { Heading, TextMuted } from "@/components/elements/Typography";
import { isDevelopment } from "@/helpers/environment";
import { useMountEffect } from "@/hooks/useMountEffect";
import { useMixpanel } from "@/monitoring/mixpanel";
import { useToast } from "@/providers/ToastProvider";
import * as Sentry from "@sentry/react";
import { Search, useSearch } from "@tanstack/react-location";

import { useStripeObject } from "../../shared";
import { formatPlanName } from "../lib";
import { useCreateOrUpdateSubscription } from "./hooks/useCreateSubscription";

export type CheckoutContext = {
  customerId: string;
  priceId: string;
  currency: string;
  numConnectors: number;
  numUsers: number;
};

export function ConfirmSubscriptionContainer(props: { onClose: () => void }) {
  const stripe = useStripeObject();
  const toast = useToast();
  const mixpanel = useMixpanel();

  const search = useSearch<
    Search<{
      setup_intent_client_secret: string;
      checkout_context: CheckoutContext;
    }>
  >();

  const [error, _setError] = useState<{
    code: string;
    message: string;
  } | null>(null);

  const setError = (error: {
    code: string;
    message: string;
    context?: Record<string, any> | null;
  }) => {
    _setError({
      code: error.code,
      message: error.message,
    });
    Sentry.captureException("Confirm subscription setup failed", {
      extra: error,
    });
  };

  const [createSubscription] = useCreateOrUpdateSubscription({
    onSubscriptionCreated: async ({ planName }) => {
      toast(
        `Welcome to WELD ${formatPlanName(planName)}!`,
        "Your subscription has been set up successfully",
        "success",
      );
      props.onClose();
    },
    onSubscriptionPlanChanged: () => {
      toast(
        "Your subscription has been set up successfully",
        undefined,
        "success",
      );
      props.onClose();
    },
  });

  const [isLoading, setIsLoading] = useState(false);

  useMountEffect(async () => {
    async function prepareSubscription(
      clientSecret: string,
      checkoutContext: CheckoutContext,
    ): Promise<{
      data?: string;
      error?: { code: string; message: string };
    }> {
      const { setupIntent, error } =
        await stripe.retrieveSetupIntent(clientSecret);
      if (error) {
        Sentry.captureException("Stripe - Confirm Setup Failed", {
          extra: {
            code: error.code,
            doc_url: error.doc_url,
            message: error.message,
            type: error.type,
            payment_method: error.payment_method?.id,
            setup_intent: error.setup_intent?.id,
          },
        });
        if (isDevelopment) {
          // eslint-disable-next-line
          console.debug("Stripe - Confirm Setup failed", error);
        }
        return {
          data: undefined,
          error: {
            code: "payment_setup_error",
            message: error.message || "Payment setup failed",
          },
        };
      }

      const paymentMethodId = setupIntent.payment_method;
      if (!paymentMethodId) {
        return {
          data: undefined,
          error: {
            code: "payment_setup_error",
            message: "Payment method missing",
          },
        };
      }

      mixpanel.track("Checkout Payment Method Created");

      return await createSubscription({
        customerId: checkoutContext.customerId,
        priceId: checkoutContext.priceId,
        currency: checkoutContext.currency,
        paymentMethodId: paymentMethodId as string,
        numConnectors: checkoutContext.numConnectors,
        numUsers: checkoutContext.numUsers,
      });
    }

    if (!search.setup_intent_client_secret) {
      setError({
        code: "missing_setup_intent",
        message: "Subscription setup failed due to missing context",
      });
      return;
    }

    const validateCheckoutContext = (
      checkoutContext: any | undefined,
    ): checkoutContext is CheckoutContext => {
      if (typeof checkoutContext !== "object" || !checkoutContext) {
        return false;
      }
      return (
        checkoutContext.customerId &&
        checkoutContext.priceId &&
        checkoutContext.currency &&
        checkoutContext.numConnectors &&
        checkoutContext.numUsers
      );
    };

    if (!validateCheckoutContext(search.checkout_context)) {
      setError({
        code: "missing_context",
        message: "Subscription setup failed due to missing context",
      });
      return;
    }

    try {
      setIsLoading(true);
      const { error } = await prepareSubscription(
        search.setup_intent_client_secret,
        search.checkout_context,
      );
      if (error) {
        setError(error);
        setIsLoading(false);
      }
    } catch (error) {
      if (error instanceof Error) {
        setError({
          code: "error",
          message: error.message,
        });
      }
      setIsLoading(false);
    }
  });

  return (
    <div className="flex items-center justify-center">
      <div className="p-10">
        {isLoading && (
          <div className="flex items-center gap-3">
            <LoadingSpinner />
            <div>Creating subscription...</div>
          </div>
        )}
        {error && (
          <>
            <Heading className="mb-4">Error</Heading>
            <div>{error.message}</div>
            <TextMuted className="my-4 text-sm" as="div">
              code: {error.code}
            </TextMuted>
            <Button onClick={props.onClose} size="sm">
              Close
            </Button>
          </>
        )}
      </div>
    </div>
  );
}
