import { DateAndTimeShort } from "constants/timeStringDisplay";
import dayjs from "dayjs";
import { useMemo } from "react";

import {
  EltSync,
  FindSyncHistoryResponse,
  SyncJobStatus,
  useSourceStreamSyncStatesQuery,
} from "@/apollo/types";
import { BadgeVariant } from "@/components/elements/Badge_Legacy";
import { StatusLED } from "@/components/elements/StatusLED";
import { ELTSyncStatus, useEltSyncStatus } from "@/features/elt";
import { useSourceStreamNotifications } from "@/features/notifications";
import { useEltSync } from "@/hooks/useSync";

type Props = {
  eltSyncId: string;
};

const EltSyncStatusLED = (props: Props) => {
  const { status } = useEltSyncStatus(props.eltSyncId);

  switch (status) {
    case ELTSyncStatus.NOT_STARTED:
      return (
        <StatusLED variant={BadgeVariant.Info}>
          <span>{"Not started"}</span>
        </StatusLED>
      );
    case ELTSyncStatus.PAUSED:
      return (
        <StatusLED variant={BadgeVariant.Info}>
          <span>{"Paused"}</span>
        </StatusLED>
      );
    case ELTSyncStatus.NO_SYNC:
      return (
        <StatusLED variant={BadgeVariant.Info}>
          <span>{"Starting now"}</span>
        </StatusLED>
      );
    case ELTSyncStatus.ALL_SYNCED:
      return (
        <StatusLED variant={BadgeVariant.Success}>
          <span>{"All synced"}</span>
        </StatusLED>
      );
    case ELTSyncStatus.ALL_FAILING:
      return (
        <StatusLED variant={BadgeVariant.Error}>
          <span>{"All tables failed on latest sync"}</span>
        </StatusLED>
      );
    case ELTSyncStatus.PARTIAL_FAILING:
      return (
        <StatusLED variant={BadgeVariant.Error}>
          <span>{"Tables failed on latest sync"}</span>
        </StatusLED>
      );
    default:
      return null;
  }
};

export default EltSyncStatusLED;

export const RawViewStatusLED = (props: {
  eltSyncId: string;
  sourceStream: string;
}) => {
  const { eltSync } = useEltSync(props.eltSyncId);
  const { data } = useSourceStreamSyncStatesQuery({
    variables: {
      syncId: props.eltSyncId,
    },
  });
  const sourceStreamLatestHistory = useMemo(() => {
    return data?.sourceStreamSyncStates.sourceStreams.find(
      (stream) => stream.name === props.sourceStream,
    );
  }, [data, props.sourceStream]);

  if (!eltSync) return null;
  return (
    <RawViewSyncStatusLED
      eltSync={eltSync}
      sourceStream={props.sourceStream}
      sourceStreamLatestHistory={sourceStreamLatestHistory?.latestSync}
    />
  );
};

const RawViewSyncStatusLED = (props: {
  eltSync: Pick<EltSync, "id" | "sourceIntegrationId" | "sourceConnectionId">;
  sourceStream: string;
  sourceStreamLatestHistory?: Pick<
    FindSyncHistoryResponse,
    "status" | "finishedAt" | "totalRecords" | "errorMessage"
  > | null;
}) => {
  const notifications = useSourceStreamNotifications(
    props.eltSync,
    props.sourceStream,
  );

  const latestRunDate = props.sourceStreamLatestHistory?.finishedAt
    ? dayjs(props.sourceStreamLatestHistory.finishedAt)
    : null;

  //if notification, show error..
  if (notifications.length > 0) {
    return (
      <StatusLED variant={BadgeVariant.Error}>
        <span>{"Error on latest sync"}</span>
      </StatusLED>
    );
  }

  //if latest sync failed, show error
  if (props.sourceStreamLatestHistory?.status === SyncJobStatus.Failed) {
    return (
      <StatusLED variant={BadgeVariant.Error}>
        <span>{"Failed on latest sync"}</span>
      </StatusLED>
    );
  }

  //if latest sync succeeded, show success
  if (props.sourceStreamLatestHistory?.status === SyncJobStatus.Completed) {
    return (
      <StatusLED variant={BadgeVariant.Success}>
        <span>{`Succeeded on latest sync. (${latestRunDate?.format(
          DateAndTimeShort,
        )})`}</span>
      </StatusLED>
    );
  }
  if (props.sourceStreamLatestHistory) {
    return (
      <StatusLED variant={BadgeVariant.Success} pulse>
        <span>{"Updating now"}</span>
      </StatusLED>
    );
  }

  return null;
};
