import { isEmpty } from "lodash";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import {
  ActiveInitialSyncsDocument,
  ConnectionBaseFragment,
  ConnectionDocument,
  CreateEltSyncMutation,
  CreateEltSyncMutationVariables,
  EltSyncsDocument,
  useConnectionQuery,
  useCreateEltSyncMutation,
} from "@/apollo/types";
import { SourceStreamsState } from "@/features/elt/reducers/sourceStreamsReducer";
import useConnectionSettings, {
  ConnectionSettingsType,
} from "@/hooks/useConnectionSettings";
import { useLatestValueRef } from "@/hooks/useLatestValueRef";
import { useIntegration } from "@/integrations";
import { useCurrentAccount } from "@/providers/account";

import {
  DataSourceState,
  DataSourceStateContext,
  createInitialState,
} from "../step-state";

type DataSourceCreateResult = CreateEltSyncMutation["createEltSync"];

const DataSourceStepsContext = createContext<{
  integrationId?: string;
  connection?: ConnectionBaseFragment;
  connectionHasSettings: boolean | undefined;
  connectionSettings: ConnectionSettingsType;
  isLoading: boolean;
  onResetConfig: () => void;
  onCancelSetup: (options?: { forceClose?: boolean }) => void;
  createResult: DataSourceCreateResult | null;
  onDataSourceCreated: (dataSource: DataSourceCreateResult) => void;
  initialFocusRef?: React.MutableRefObject<HTMLElement | null>;
} | null>(null);

export type DataSourceStepsContextType = NonNullable<
  React.ContextType<typeof DataSourceStepsContext>
>;

export function useDataSourceStepContext() {
  const ctx = useContext(DataSourceStepsContext);
  if (!ctx) {
    throw new Error(
      "useDataSourceStepContext must be used within a DataSourceStepsContext.Provider",
    );
  }
  return ctx;
}

export function DataSourceStepsContextProvider(props: {
  integrationId?: string;
  connectionId?: string;
  children: React.ReactNode;
  onCancel: (options?: { forceClose?: boolean }) => void;
  onDirtyChange: (isDirty: boolean) => void;
  initialFocusRef?: React.MutableRefObject<HTMLElement | null>;
}) {
  const [state, setState] = useState(
    createInitialState({
      integrationId: props.integrationId,
      sourceId: props.connectionId,
    }),
  );
  const sourceId = state.source.value.sourceId;

  const onDirtyChangeRef = useLatestValueRef(props.onDirtyChange);
  const hasDirtySteps = !isEmpty(state.source.value.sourceId);

  const [createResult, setCreateResult] =
    useState<DataSourceCreateResult | null>(null);

  useEffect(() => {
    if (createResult) {
      // Data source has been created, step flow is done and no longer dirty
      onDirtyChangeRef.current(false);
    } else {
      onDirtyChangeRef.current(hasDirtySteps);
    }
  }, [hasDirtySteps, createResult, onDirtyChangeRef]);

  const connectionSettings = useConnectionSettings(sourceId, "ELT");

  const { data } = useConnectionQuery({
    variables: {
      id: sourceId,
    },
    skip: !sourceId,
  });
  const connection = data?.connection;
  const integrationId = sourceId
    ? connection?.integrationId
    : state.source.value.integrationId;

  const integration = useIntegration(integrationId);

  const connectionHasSettings =
    !sourceId || connectionSettings.loading
      ? undefined
      : connectionSettings.required || integration?.supportsStartDate === true;

  const stepContext = {
    integrationId,
    connection,
    connectionHasSettings,
    connectionSettings,
    isLoading: connectionSettings.loading,
    onResetConfig: () => {
      setState(createInitialState());
      setCreateResult(null);
    },
    onCancelSetup: props.onCancel,
    createResult,
    onDataSourceCreated: (dataSource: DataSourceCreateResult) => {
      setCreateResult(dataSource);
    },
    initialFocusRef: props.initialFocusRef,
  };

  return (
    <DataSourceStateContext.Provider value={{ state, setState }}>
      <DataSourceStepsContext.Provider value={stepContext}>
        {props.children}
      </DataSourceStepsContext.Provider>
    </DataSourceStateContext.Provider>
  );
}

function stateToMutationVariables(
  state: DataSourceState,
): Omit<CreateEltSyncMutationVariables, "destinationConnectionId"> {
  const source = state.source.value;
  const sourceSettings = state.sourceSettings.value;
  const tables = state.tables.value;
  const syncSettings = state.syncSettings.value;

  return {
    sourceConnectionId: source.sourceId,
    syncInterval: syncSettings.scheduleKey,
    sourceStreams: sourceStreamStateToSourceStreamInput(
      Object.fromEntries(tables.sourceStreams),
    ),
    startDate: sourceSettings.startDate,
    config: JSON.stringify({
      sourceSettings: state.sourceSettings.value.sourceSettings,
    }),
    destinationSchemaName: syncSettings.destinationSchemaName,
    schedulerType: syncSettings.schedulerType,
    orchestrationWorkflowId: syncSettings.orchestrationWorkflowId,
  };
}

type SourceStream = NonNullable<SourceStreamsState[keyof SourceStreamsState]>;
export const sourceStreamStateToSourceStreamInput = (
  sourceStreamState: SourceStreamsState,
) => {
  return Object.entries(sourceStreamState)
    .filter((entry): entry is [string, SourceStream] => !!entry[1])
    .map(([name, stream]) => ({ name, ...stream }))
    .map(({ isIncrementalInvalid, ...stream }) => ({
      ...stream,
      selectable: undefined,
      substreams: Object.entries(stream.substreams).map(
        ([name, substream]) => ({
          name,
          ...substream,
        }),
      ),
    }));
};

export function useCreateSync(
  options: Parameters<typeof useCreateEltSyncMutation>[0],
): [
  (
    state: DataSourceState,
  ) => ReturnType<ReturnType<typeof useCreateEltSyncMutation>[0]>,
  ReturnType<typeof useCreateEltSyncMutation>[1],
] {
  const account = useCurrentAccount();
  const { onDataSourceCreated } = useDataSourceStepContext();

  const [createEltSync, result] = useCreateEltSyncMutation({
    ...options,
    onCompleted: (data, clientOptions) => {
      onDataSourceCreated(data.createEltSync);
      options?.onCompleted?.(data, clientOptions);
    },
  });

  return [
    useCallback(
      (state: DataSourceState) => {
        if (!account.dataWarehouseConnectionId) {
          throw new Error("No account - needed for elt sync");
        }
        return createEltSync({
          variables: {
            ...stateToMutationVariables(state),
            destinationConnectionId: account.dataWarehouseConnectionId,
          },
          refetchQueries: [
            { query: EltSyncsDocument },
            {
              query: ConnectionDocument,
              variables: { id: state.source.value.sourceId },
            },
            {
              query: ActiveInitialSyncsDocument,
            },
          ],
        });
      },
      [account, createEltSync],
    ),
    result,
  ];
}
