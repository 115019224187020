import React, { Fragment, createContext } from "react";

import { classed } from "@/components/classed.config";
import { default as classNames, default as cn } from "@/helpers/classNames";
import { Dialog, Transition } from "@headlessui/react";

import { IconButton } from "../Button";
import { CloseButton, CloseButtonIcon } from "../CloseButton";

type ModalSize = "sm" | "md" | "lg" | "xl" | "2xl" | "full";

function getPanelWidth(size: ModalSize = "md") {
  switch (size) {
    case "sm":
      return "sm:max-w-md";
    case "md":
      return "sm:max-w-lg";
    case "lg":
      return "sm:max-w-4xl";
    case "xl":
      return "sm:max-w-5xl";
    case "2xl":
      return "sm:max-w-7xl";
    case "full":
      return "sm:max-w-full";
  }
}

const ModalContext = createContext<{
  onClose: () => void;
} | null>(null);

const useModalContext = () => {
  const ctx = React.useContext(ModalContext);
  if (!ctx) {
    throw new Error("useModalContext must be used within a Modal");
  }
  return ctx;
};

type ModalProps = {
  isOpen: boolean;
  size?: ModalSize;
  onClose: () => void;
  centerOnScreen?: boolean;
};

export function Modal(props: React.PropsWithChildren<ModalProps>) {
  const ctx = { onClose: props.onClose };
  return (
    <Transition.Root appear show={props.isOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        className={classNames(
          "fixed inset-0 z-50 flex items-center justify-center overflow-y-auto",
          {
            "items-start": props.centerOnScreen === false,
          },
        )}
        open={props.isOpen}
        onClose={props.onClose}
        onClick={(e: React.MouseEvent<HTMLDivElement>) => {
          e.stopPropagation();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay
            className={classNames(
              "fixed inset-0 bg-black/40 transition-opacity",
            )}
          />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <Dialog.Panel
            as="section"
            className={classNames(
              "my-8 flex w-full transform flex-col overflow-auto rounded-lg bg-white text-left align-bottom shadow-xl transition-all dark:border dark:bg-gray-800 dark:text-white sm:align-middle",

              getPanelWidth(props.size),
            )}
          >
            <ModalContext.Provider value={ctx}>
              {props.children}
            </ModalContext.Provider>
          </Dialog.Panel>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  );
}

export function ModalCloseButton(
  props: Partial<React.ComponentProps<typeof IconButton>>,
) {
  const { onClose } = useModalContext();
  return (
    <CloseButton
      size="md"
      icon={<CloseButtonIcon className="h-6 w-6" />}
      {...props}
      className={cn(
        "absolute right-2 top-2 z-10 h-9 w-9 min-w-min rounded",
        props.className,
      )}
      onClick={(e) => {
        props.onClick?.(e);
        onClose();
      }}
      {...props}
    />
  );
}

export const ModalHeader = classed.header(
  "px-6 py-4 grow-0 shrink-1 basis-0 text-lg",
);

export const ModalBody = classed.div("flex-1 px-6 py-2");

export const ModalFooter = classed.footer(
  "flex justify-end items-center px-6 py-4 gap-4",
);
