import produce from "immer";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useCallback, useMemo } from "react";
import { v4 as uuid } from "uuid";

import {
  PreviewQueryDocument,
  PreviewQueryQuery,
  PreviewQueryQueryVariables,
  QueryDependencyInput,
  QueryResultStatusEnum,
  useAsyncQueryPreviewResultLazyQuery,
  useAsyncQueryStatusQuery,
  useCreateAsyncQueryPreviewMutation,
} from "@/apollo/types";
import { useStoreLatestPreviewForTab } from "@/components/modules/ModelTabs";
import { useCurrentAccount } from "@/providers/account";
import { useApolloClient } from "@apollo/client";

import { ModelType } from "../ModelEditorStore";
import { useOpenPaneOnExecute } from "../QueryEditor/EditorWithBar";
import { PreviewType, usePreviewOpenAtom, usePreviewsAtom } from "./Preview";

type HandleQueryOptions = {
  sqlQuery: string;
  dependencies: QueryDependencyInput[];
  modelName: string | null;
  historyDate?: Date;
  modelId: string;
  modelType: ModelType;
  isTextSelection: boolean;
  chatTreadId?: string;
};

export function usePreview() {
  const client = useApolloClient();
  const account = useCurrentAccount();
  const dataWarehouseConnectionId = account.dataWarehouseConnectionId ?? "";

  const [, setPreviews] = usePreviewsAtom();
  const [, setPreviewOpen] = usePreviewOpenAtom();

  const storeLatestPreviewForTab = useStoreLatestPreviewForTab();

  const handleQueryOld = useCallback(
    async (options: HandleQueryOptions) => {
      const id = uuid();
      setPreviews((prev) => [
        {
          id,
          state: "loading",
          startTimestamp: Date.now(),
          endTimestamp: null,
          displayName: options.modelName,
          historyDate: options.historyDate,
          modelId: options.modelId,
          weldSQL: options.sqlQuery,
          dependencies: options.dependencies,
          modelType: options.modelType,
        },
        ...prev.filter((p) => p.modelId !== options.modelId),
      ]);

      // Filter out dependencies that are not used in the query if it's a text selection
      const dependencies = options.isTextSelection
        ? filterDependenciesByQuery(options.dependencies, options.sqlQuery)
        : options.dependencies;

      setPreviewOpen(id);
      storeLatestPreviewForTab(id);

      client
        .query<PreviewQueryQuery, PreviewQueryQueryVariables>({
          query: PreviewQueryDocument,
          fetchPolicy: "network-only",
          variables: {
            dependencies: dependencies,
            weldSql: options.sqlQuery,
            sourceConnectionId: dataWarehouseConnectionId,
          },
        })
        .then((res) => {
          if (res.error) {
            setPreviews((prev) =>
              prev.map((p) =>
                p.id === id
                  ? {
                      ...p,
                      state: "error",
                      message: res.error?.message,
                      endTimestamp: Date.now(),
                    }
                  : p,
              ),
            );
          } else {
            setPreviews((prev) =>
              prev.map((p) =>
                p.id === id
                  ? {
                      ...p,
                      state: "success",
                      rows: res.data.previewQuery.response,
                      dwSql: res.data.previewQuery.dwSql,
                      endTimestamp: Date.now(),
                    }
                  : p,
              ),
            );
          }
        })
        .catch((error) => {
          setPreviews((prev) =>
            prev.map((p) =>
              p.id === id
                ? {
                    ...p,
                    state: "error",
                    message: error?.message || JSON.stringify(error),
                    endTimestamp: Date.now(),
                  }
                : p,
            ),
          );
        });
    },
    [
      setPreviews,
      setPreviewOpen,
      storeLatestPreviewForTab,
      client,
      dataWarehouseConnectionId,
    ],
  );

  const [createAsyncQuery] = useCreateAsyncQueryPreviewMutation();

  const handleQueryAsync = useCallback(
    async (options: HandleQueryOptions) => {
      const id = uuid();
      const newPreviewItem: PreviewType = {
        id,
        state: "pending",
        startTimestamp: Date.now(),
        endTimestamp: null,
        displayName: options.modelName,
        historyDate: options.historyDate,
        modelId: options.modelId,
        weldSQL: options.sqlQuery,
        dependencies: options.dependencies,
        modelType: options.modelType,
        chatThreadId: options.chatTreadId,
      };

      // Filter out dependencies that are not used in the query if it's a text selection
      const dependencies = options.isTextSelection
        ? filterDependenciesByQuery(options.dependencies, options.sqlQuery)
        : options.dependencies;

      setPreviews((prev) => [
        newPreviewItem,
        ...prev.filter((p) => p.modelId !== newPreviewItem.modelId),
      ]);
      setPreviewOpen(id);
      storeLatestPreviewForTab(id);

      createAsyncQuery({
        variables: {
          dependencies: dependencies,
          weldSql: options.sqlQuery,
          sourceConnectionId: dataWarehouseConnectionId,
        },
      })
        .then((response) => {
          const result = response.data?.createAsyncQueryPreview;
          if (!result) return;
          setPreviews((prev) =>
            produce(prev, (draft) => {
              draft.forEach((preview) => {
                if (preview.id === id) {
                  preview.state = "loading";
                  preview.queryExecutionId = result.queryExecutionId;
                  preview.dwSql = result.dwSql;
                }
              });
            }),
          );
        })
        .catch((error) => {
          setPreviews((prev) =>
            produce(prev, (draft) => {
              draft.forEach((preview) => {
                if (preview.id === id) {
                  preview.state = "error";
                  preview.message = error?.message || JSON.stringify(error);
                  preview.endTimestamp = Date.now();
                }
              });
            }),
          );
        });
    },
    [
      setPreviews,
      setPreviewOpen,
      createAsyncQuery,
      dataWarehouseConnectionId,
      storeLatestPreviewForTab,
    ],
  );
  const isAsyncPreviewQuerySupported =
    !!account.dataWarehouse?.supportsAsyncQuery;

  const useAsyncPreviewQuery =
    useFlags()["useAsyncPreviewQuery"] && isAsyncPreviewQuerySupported;

  usePollAsyncQuery(useAsyncPreviewQuery);

  const handleOpenPreviewPane = useOpenPaneOnExecute();

  const handleQuery = useCallback(
    (options: HandleQueryOptions) => {
      handleOpenPreviewPane();
      if (useAsyncPreviewQuery) {
        return handleQueryAsync(options);
      } else {
        return handleQueryOld(options);
      }
    },
    [
      handleOpenPreviewPane,
      handleQueryAsync,
      handleQueryOld,
      useAsyncPreviewQuery,
    ],
  );

  return handleQuery;
}

const filterDependenciesByQuery = (
  dependencies: QueryDependencyInput[],
  query: string,
) => {
  return dependencies.filter((dependency) =>
    query.includes(`{{${dependency.weldTag}}}`),
  );
};

const usePollAsyncQuery = (useAsyncPreviewQuery: boolean) => {
  const account = useCurrentAccount();

  const dataWarehouseConnectionId = account.dataWarehouseConnectionId ?? "";

  const [previews, setPreviews] = usePreviewsAtom();
  const [previewOpen] = usePreviewOpenAtom();
  const [getAsyncQueryPreviewResult] = useAsyncQueryPreviewResultLazyQuery();

  const openPreview = useMemo(() => {
    const openPreview = previews.find((p) => p.id === previewOpen);
    if (openPreview?.state !== "loading") return null;
    return openPreview;
  }, [previews, previewOpen]);

  const setPreviewError = (error: Error) => {
    setPreviews((prev) =>
      produce(prev, (draft) => {
        draft.forEach((p) => {
          if (p.id === openPreview?.id) {
            p.state = "error";
            p.message = error?.message || JSON.stringify(error);
            p.queryExecutionId = undefined;
            p.endTimestamp = Date.now();
          }
        });
      }),
    );
  };

  const shouldPoll =
    useAsyncPreviewQuery &&
    openPreview != null &&
    !!openPreview.queryExecutionId &&
    openPreview.state === "loading";

  const { stopPolling } = useAsyncQueryStatusQuery({
    variables: {
      sourceConnectionId: dataWarehouseConnectionId,
      queryExecutionId: openPreview?.queryExecutionId ?? "",
    },
    skip: !shouldPoll,
    pollInterval: shouldPoll ? 1000 : 0,
    onCompleted: async (data) => {
      if (
        !data.asyncQueryStatus ||
        data.asyncQueryStatus.status === QueryResultStatusEnum.Running
      ) {
        return;
      }

      stopPolling();

      if (data.asyncQueryStatus.status === QueryResultStatusEnum.Failed) {
        setPreviewError(new Error("Query failed with unknown error"));
        return;
      }

      await getAsyncQueryPreviewResult({
        variables: {
          sourceConnectionId: dataWarehouseConnectionId,
          queryExecutionId: openPreview?.queryExecutionId ?? "",
        },
        onCompleted: (result) => {
          if (!result.asyncQueryPreviewResult) {
            return;
          }
          const { rows, numRowsTotal } = result.asyncQueryPreviewResult;
          setPreviews((p) => {
            return produce(p, (draft) => {
              draft.forEach((p) => {
                if (p.id === openPreview?.id) {
                  p.rows = rows;
                  p.totalRows = numRowsTotal;
                  p.state = "success";
                  p.endTimestamp = Date.now();
                  p.queryExecutionId = undefined;
                }
              });
            });
          });
        },
      });
    },
    // Describing similar issue: https://github.com/apollographql/apollo-client/issues/5531
    notifyOnNetworkStatusChange: true,
    refetchWritePolicy: "overwrite",
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    onError: (error) => {
      setPreviewError(error);
    },
  });
};
