import { useMemo } from "react";

import { IntegrationType } from "@/integrations";

import { createEmptyIntegration } from "./helpers";
import { useAvailableIntegrations } from "./useAvailableIntegrations";
import {
  ConnectorOption,
  createConnectorOption,
} from "./useCreateConnectorOptions";

const createComingSoonOption = (integrationId: string, name: string) => {
  const option = createConnectorOption(
    createEmptyIntegration(integrationId, name, [
      "EltSourceConnection",
      "ReverseEltDestinationConnection",
    ]),
  );
  option.isAccessRequestable = true;
  option.isSoonConnector = true;
  return option;
};

const comingSoonConnectors: ConnectorOption[] = [
  ["amazon-vendor-central", "Amazon Vendor Central API"],
  ["pardot", "Salesforce Marketing Cloud (Pardot)"],
  ["marketo", "Marketo"],
  ["workday", "Workday"],
  ["typeform", "Typeform"],
  ["dixa", "Dixa"],
  ["reddit", "Reddit"],
  ["xAds", "X Ads (Twitter)"],
  ["six-sense", "Six Sense"],
  ["appsflyer", "AppsFlyer"],
  ["azure-analytics", "Azure Analytics"],
  ["azure-blob", "Azure Blob"],
  ["azure-cosmos", "Azure Cosmos"],
  ["azure-sql", "Azure SQL"],
  ["azure-synapse", "Azure Synapse"],
  ["firebase", "Firebase"],
  ["freshchat", "Freshchat"],
  ["freshsales", "Freshsales"],
  ["freshservice", "Freshservice"],
  ["google-cloud-storage", "Google Cloud Storage"],
  ["google-video", "Google Video"],
  ["iterable", "Iterable"],
  ["netsuite", "Netsuite"],
  ["pendo", "Pendo"],
  ["segment", "Segment"],
].map(([id, name]) => createComingSoonOption(id, name));

export const useComingSoonConnectorOptions = (dwh: IntegrationType) => {
  const integrations = useAvailableIntegrations(dwh);
  return useMemo(() => {
    const availableIntegrationIds = new Set(integrations.map((c) => c.id));
    //Return all beta connectors that are not available:
    return comingSoonConnectors.filter((connector) => {
      return !availableIntegrationIds.has(connector.integration.id);
    });
  }, [integrations]);
};
