import { ReactElement, ReactNode, cloneElement } from "react";

import { Overlay, OverlayBackdrop } from "../layout/Overlay";

type FeatureGuardProps = {
  test: boolean;
  fallback?:
    | ReactElement
    | ((props: { backgroundContent: ReactNode }) => JSX.Element);
  children: ReactNode;
};

export function FeatureGuard(props: FeatureGuardProps): JSX.Element | null {
  if (props.test) {
    return <>{props.children}</>;
  }
  if (!props.fallback) {
    return null;
  }
  if (typeof props.fallback === "function") {
    return props.fallback({ backgroundContent: props.children });
  }
  return cloneElement(props.fallback, {
    backgroundContent: props.children,
  });
}

export function FeatureGuardFallbackWithOverlay(props: {
  backgroundContent?: React.ReactNode;
  children: React.ReactNode;
}) {
  return (
    <>
      {props.backgroundContent}
      <Overlay className="absolute">
        <OverlayBackdrop />
        <div className="relative flex h-full w-full items-center justify-center">
          <div className="m-4 max-w-lg rounded bg-white p-8 shadow-xl dark:border dark:bg-gray-800 dark:text-white">
            {props.children}
          </div>
        </div>
      </Overlay>
    </>
  );
}
