import dayjs, { ConfigType } from "dayjs";

import {
  FindSyncHistoryResponseFragment,
  FullSyncTrigger,
} from "@/apollo/types";
import useNow from "@/hooks/useNow";
import { formatBytes, formatDate, formatDuration } from "@/shared/formatters";

import { formatFullSyncTrigger, formatOperationMode } from "../lib/formatters";
import { SyncStatusText } from "./SyncStatusText";

export function SyncHistoryInfo({
  details,
  liveDuration,
}: {
  details: FindSyncHistoryResponseFragment;
  liveDuration?: boolean;
}) {
  return (
    <ul className="space-y-1">
      <li>
        Status: <SyncStatusText details={details} />
      </li>
      <li>
        Sync start:{" "}
        {formatDate(details.startedAt, {
          dateStyle: "short",
          timeStyle: "long",
        })}
      </li>
      {details.finishedAt && (
        <>
          <li>
            Sync end:{" "}
            {formatDate(details.finishedAt, {
              dateStyle: "short",
              timeStyle: "long",
            })}
          </li>
          <li>
            Duration:{" "}
            <DurationText
              startedAt={details.startedAt}
              finishedAt={details.finishedAt}
            />
          </li>
        </>
      )}
      {liveDuration && details.finishedAt == null && (
        <li>
          Elapsed: <ElapsedTime startedAt={details.startedAt} />
        </li>
      )}
      {details.syncedRecords != null && details.syncedRecords !== 0 && (
        <>
          <li>Rows synced: {details.syncedRecords}</li>
          <li>
            Data volume:{" "}
            {formatBytes((details.sizeSyncedMb || 0) * 1024 * 1024)}
          </li>
        </>
      )}
      {details.operationMode != null && (
        <li>
          Mode:{" "}
          {formatOperationMode(details.operationMode, details.fullSyncTrigger)}
        </li>
      )}
      {details.fullSyncTrigger != null &&
        details.fullSyncTrigger !== FullSyncTrigger.None && (
          <li>
            Full sync trigger: {formatFullSyncTrigger(details.fullSyncTrigger)}
          </li>
        )}
    </ul>
  );
}

function DurationText(props: {
  startedAt: ConfigType;
  finishedAt: ConfigType;
}) {
  const { startedAt, finishedAt } = props;
  return (
    <span>
      {formatDuration(dayjs(finishedAt).valueOf() - dayjs(startedAt).valueOf())}
    </span>
  );
}

function ElapsedTime(props: { startedAt: string }) {
  const now = useNow(1000);
  return <DurationText startedAt={props.startedAt} finishedAt={now} />;
}
