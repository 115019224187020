import dayjs from "dayjs";
import { keyBy } from "lodash";
import React, { useMemo } from "react";
import { createPortal } from "react-dom";
import { usePopper } from "react-popper";

import {
  SyncJobStatus,
  useEltSyncQuery,
  useSourceStreamSyncStatesQuery,
} from "@/apollo/types";
import { BadgeStatus, BadgeVariant } from "@/components/elements/Badge_Legacy";
import { Button } from "@/components/elements/Button";
import LoadingSpinner from "@/components/elements/LoadingSpinner";
import { StatusLED } from "@/components/elements/StatusLED";
import { useViewDataSourceSlideOver } from "@/components/modules/view-data-source-slideover";
import classNames from "@/helpers/classNames";
import { IntegrationLogoBox } from "@/integrations";
import { useSocketEvent } from "@/socket/SocketContext";
import { Popover } from "@headlessui/react";
import { CircleStackIcon } from "@heroicons/react/24/outline";

import { EltStreamItemType, ViewItemType } from "../SidebarFactory";
import { ELTStreamOptions, RawDataOptions } from "./DataSourcesOptions";

export const RawViewItem = ({ item }: { item: ViewItemType }) => {
  const viewName = item.rawView.sourceStream;

  const syncId = item.rawView.syncId;
  const eltSyncQuery = useEltSyncQuery({
    variables: { eltSyncId: syncId ?? "" },
    skip: !syncId,
  });

  const sourceStreamSyncStatesQuery = useSourceStreamSyncStatesQuery({
    variables: {
      syncId: syncId ?? "",
    },
    skip: !syncId,
  });

  useSocketEvent("elt-sync:updated", {
    onMessage(resp) {
      if (resp.payload.scheduledJobId === item.rawView?.scheduledJobId) {
        sourceStreamSyncStatesQuery.refetch();
        eltSyncQuery.refetch();
      }
    },
  });

  const sourceStream = eltSyncQuery.data?.eltSync?.sourceStreams.find(
    (s) => s.name === item.rawView.sourceStream,
  );
  const sourceStreamJob = sourceStream?.job;

  const sourceStreamsSyncStates =
    sourceStreamSyncStatesQuery.data?.sourceStreamSyncStates.sourceStreams;

  const sourceStreamSyncState = sourceStreamsSyncStates?.find(
    (h) => h.scheduledJobId === item.rawView?.scheduledJobId,
  );

  const latestFinishedHistory = sourceStreamSyncState?.latestSync;

  let jobInfo = (() => {
    if (sourceStreamSyncStatesQuery.loading || eltSyncQuery.loading) {
      return null;
    }
    if (item.isViewImported) {
      return null;
    }

    if (sourceStreamJob == null) {
      return {
        variant: BadgeVariant.Info,
        message: "This view is longer actively synced",
      };
    }

    if (sourceStreamSyncState?.activeSync != null) {
      return {
        variant: BadgeVariant.Default,
        message: "Running",
        pulse: true,
      };
    }
    if (sourceStreamSyncState?.latestSync?.status) {
      return {
        variant: BadgeStatus[sourceStreamSyncState.latestSync.status],
        message: JobStatusMessages[sourceStreamSyncState.latestSync.status],
      };
    }
    return { variant: BadgeVariant.Info };
  })();

  const completedDate = (() => {
    if (
      latestFinishedHistory?.finishedAt &&
      latestFinishedHistory?.status === SyncJobStatus.Completed
    ) {
      return dayjs(latestFinishedHistory.finishedAt).format("YYYY-MM-DD HH:mm");
    }
    return null;
  })();

  //Mark as a stonly element if this is from a synced connector.
  const markForStonly = !!item.rawView && !!item.rawView.syncId;

  return (
    <div
      data-stonly={markForStonly ? "available-data-source" : undefined}
      className="flex w-full items-center gap-1.5 py-0.5"
    >
      <CircleStackIcon className="w-4 shrink-0 text-gray-500" />
      <span className="grow truncate text-xs">{viewName}</span>
      {jobInfo && (
        <StatusLED
          variant={jobInfo.variant}
          pulse={sourceStreamJob?.running ?? false}
        >
          {(jobInfo.message != null || completedDate) && (
            <div className="space-y-1">
              <div>{jobInfo.message}</div>
              {completedDate && <div className="text-xs">{completedDate}</div>}
            </div>
          )}
        </StatusLED>
      )}
      <RawDataOptions item={item} />
    </div>
  );
};

/*
 * This component renders streams in the sidebar, only if there is not yet a raw view for that stream.
 */
export const EltStreamItem = (props: { item: EltStreamItemType }) => {
  const { streamName, sync } = props.item;

  const eltSyncQuery = useEltSyncQuery({
    variables: { eltSyncId: sync.id },
    skip: !sync.id,
  });

  const historyQuery = useSourceStreamSyncStatesQuery({
    variables: { syncId: sync.id },
    skip: !sync.id,
  });

  useSocketEvent("elt-sync:updated", {
    onMessage(resp) {
      if (resp.payload.syncId === sync.id) {
        historyQuery.refetch();
        eltSyncQuery.refetch();
      }
    },
  });

  const syncHistoryDict = useMemo(
    () =>
      keyBy(historyQuery.data?.sourceStreamSyncStates.sourceStreams, "name"),
    [historyQuery.data?.sourceStreamSyncStates.sourceStreams],
  );

  const job = (() => {
    return eltSyncQuery.data?.eltSync?.sourceStreams.find(
      (s) => s.name === streamName,
    )?.job;
  })();

  const isRunningNow = syncHistoryDict[streamName]?.activeSync != null;

  const info = (() => {
    if (job?.status === "STOPPED") {
      return { variant: BadgeVariant.Info, message: "Stopped" };
    }
    if (
      syncHistoryDict[streamName]?.latestSync?.status === SyncJobStatus.Failed
    ) {
      return {
        variant: BadgeVariant.Error,
        message: "Failed",
      };
    } else if (
      syncHistoryDict[streamName]?.latestSync?.status ===
      SyncJobStatus.Completed
    ) {
      return {
        variant: BadgeVariant.Info,
        message: "No data available",
      };
    }
  })();

  return (
    <div className={classNames("flex w-full items-center gap-1 py-0.5")}>
      <CircleStackIcon className="w-4 shrink-0 text-gray-500 opacity-40" />
      <span className="grow truncate text-xs opacity-40">
        {normalizeTableName(streamName)}
      </span>
      {isRunningNow && <LoadingSpinner className="h-3 w-3" />}
      {info && !isRunningNow && (
        <div className="grid h-3 w-3 shrink-0 place-items-center opacity-40">
          <StatusLED variant={info.variant}>
            <span>{info.message}</span>
          </StatusLED>
        </div>
      )}
      <ELTStreamOptions item={props.item} />
    </div>
  );
};

/*
 * Wrapped around the ELT stream item to make clicking it product a tooltip
 */
export const EltStreamClickable = (props: {
  item: EltStreamItemType;
  children: React.ReactNode;
}) => {
  let [referenceElement, setReferenceElement] =
    React.useState<HTMLDivElement | null>();
  let [popperElement, setPopperElement] =
    React.useState<HTMLDivElement | null>();
  let { styles, attributes } = usePopper(referenceElement, popperElement, {
    strategy: "absolute",
    placement: "bottom-start",
    modifiers: [
      { name: "preventOverflow", enabled: true },
      { name: "offset", options: { offset: [-10, 0] } },
    ],
  });

  const tableName = useMemo(
    () =>
      `raw.${props.item.sync.destinationSchemaName}.${normalizeTableName(
        props.item.streamName,
      )}`,
    [props.item.streamName, props.item.sync.destinationSchemaName],
  );

  const { onOpen: viewSync } = useViewDataSourceSlideOver();

  return (
    <Popover className="relative w-full">
      <Popover.Button
        ref={(elm) => setReferenceElement(elm as HTMLDivElement | null)}
        className={"w-full"}
        as="div"
        role="button"
      >
        {props.children}
      </Popover.Button>

      {createPortal(
        <Popover.Panel
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
          className="relative z-50 w-80 space-y-2 rounded border bg-white p-4 shadow dark:border-gray-600 dark:bg-gray-800"
        >
          <IntegrationLogoBox
            id={props.item.sync.sourceIntegrationId}
            size="sm"
          />
          <div className="truncate text-sm font-medium">{tableName}</div>

          <div className="text-xs">
            The table is not yet available. After the first data is synced, the
            table will be available for modeling.
          </div>
          <div className="pt-2">
            <Button
              className="w-full"
              size="sm"
              onClick={() =>
                viewSync({
                  syncId: props.item.sync.id,
                })
              }
            >
              Go to sync details
            </Button>
          </div>
        </Popover.Panel>,
        document.body,
      )}
    </Popover>
  );
};

const normalizeTableName = (tableName: string) => {
  return tableName.replaceAll(".", "_");
};

const JobStatusMessages: Record<string, string> = {
  COMPLETED: "Completed",
  FAILED: "Failed",
  RUNNING: "Running",
  SCHEDULED: "Scheduled",
  QUARANTINED: "Quarantined",
  STARTING: "Starting...",
  STOPPED: "Stopped",
  NO_SYNC_FINISHED: "No sync finished",
  IMPORTED: "Manually imported",
  SYNC_DELETED: "Sync deleted",
  NO_DATA: "No data available",
};
