import { startCase } from "lodash";

import {
  FindSyncHistoryResponseFragment,
  FullSyncTrigger,
  SyncJobStatus,
} from "@/apollo/types";
import cn from "@/helpers/classNames";

import { isFullSync } from "../lib/utils";

export function SyncStatusText(props: {
  details: FindSyncHistoryResponseFragment;
}) {
  const { status, fullSyncTrigger } = props.details;
  if (!status) return <span>?</span>;

  const isInitialSync = fullSyncTrigger === FullSyncTrigger.InitialSync;
  return (
    <span
      className={cn("font-semibold", {
        "text-green-600": status === SyncJobStatus.Completed,
        "text-red-600": status === SyncJobStatus.Failed,
        "text-blue-600": status === SyncJobStatus.Running,
        "text-yellow-500":
          status === SyncJobStatus.Retrying || status === SyncJobStatus.Delayed,
        "text-muted-foreground": status === SyncJobStatus.Scheduled,
      })}
    >
      {status === SyncJobStatus.Running ? (
        <>
          {isInitialSync
            ? "Running Initial Sync"
            : isFullSync(props.details.fullSyncTrigger)
              ? "Running Full Sync"
              : "Running"}
        </>
      ) : (
        startCase(status.toLocaleLowerCase())
      )}
    </span>
  );
}
