import { ActionButton } from "@/components/elements/Button";
import Center from "@/components/elements/layout/Center";
import { useViewDataSourceSlideOver } from "@/components/modules/view-data-source-slideover";
import { useMountEffect } from "@/hooks/useMountEffect";
import { IntegrationLogoBox, useIntegration } from "@/integrations";
import { useMixpanel } from "@/monitoring/mixpanel";
import { ArrowRightIcon } from "@heroicons/react/20/solid";
import { PlusIcon } from "@heroicons/react/24/outline";

import { useStepsContext } from "../../../stepper/useSteps";
import { Content, Footer } from "../../components/Layout";
import { useDataSourceStepContext } from "../../contexts/DataSourceStepsContext";

export function SetupCompleteStep() {
  const { connection, createResult, onResetConfig, onCancelSetup } =
    useDataSourceStepContext();
  const { setCurrentStepIndex } = useStepsContext();
  const sourceIntegration = useIntegration(connection?.integrationId);
  const mixpanel = useMixpanel();
  const { onOpen: viewSync } = useViewDataSourceSlideOver();

  useMountEffect(() => {
    mixpanel.track("Data Source Setup Completed Step Viewed", {
      integration_id: sourceIntegration?.id,
    });
  });

  return (
    <>
      <Content className="flex flex-col">
        <div className="grow">
          <Center className="h-full w-full">
            <div className="flex flex-col items-center gap-5 text-center">
              <IntegrationLogoBox
                id={connection?.integrationId ?? ""}
                size="xl"
              />
              <div className="space-y-5">
                <p className="max-w-md text-2xl">Initial Sync in Progress</p>
                <p className="text max-w-md text-pretty">
                  We’ve begun syncing your historical data from{" "}
                  <em>{sourceIntegration?.name}</em>. Because this is the first
                  time we’re pulling all that data,{" "}
                  <strong>it can take a while to complete</strong> — especially
                  for large tables or when rate limits apply. Data will be ready
                  for querying once the initial sync finishes.
                </p>
                <p className="max-w-md">
                  For more details on why initial syncs take time and what to
                  expect, see our {/* eslint-disable-next-line */}
                  <a
                    href="https://weld.app/docs/account/data-sources#initial-syncs"
                    target="_blank"
                    className="underline"
                  >
                    documentation
                  </a>
                  .
                </p>
              </div>
            </div>
          </Center>
        </div>
      </Content>

      <Footer>
        <ActionButton
          variant="ghost"
          colorScheme="secondary"
          onClick={() => {
            onCancelSetup({ forceClose: true });
            mixpanel.track("Close DataSourceSlideOver Button Clicked", {
              step: "Setup Complete",
            });
          }}
        >
          Close
        </ActionButton>
        <ActionButton
          colorScheme="secondary"
          icon={<PlusIcon />}
          className="ml-auto"
          onClick={() => {
            setCurrentStepIndex(0);
            onResetConfig();
            mixpanel.track("Reset DataSourceSlideOver Button Clicked", {
              step: "Setup Complete",
            });
          }}
        >
          Set up new data source
        </ActionButton>
        <ActionButton
          colorScheme="secondary"
          iconRight={<ArrowRightIcon />}
          onClick={() => {
            if (!createResult) return;
            onCancelSetup({ forceClose: true });
            viewSync({ syncId: createResult.id });
            mixpanel.track("Go To Data Source Button Clicked", {
              step: "Setup Complete",
            });
          }}
        >
          Go to data source
        </ActionButton>
      </Footer>
    </>
  );
}
