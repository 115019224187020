import { useRef } from "react";

import { NotificationType } from "@/apollo/types";

export type WorkspaceAlert = {
  id: string;
  type?: NotificationType;
  updatedAt?: Date;
  status: "info" | "warning" | "error";
  title?: string;
  message: string;
  action?: React.ReactNode;
  isDismissable?: boolean;
  onDismiss?: () => void;
  alwaysShow?: boolean;
  render?: (props: { dismiss: () => void }) => React.ReactNode;
};

export type WorkspaceAlertResolver = () => WorkspaceAlert[];

export function useWorkspaceAlerts(...resolvers: WorkspaceAlertResolver[]) {
  const resolversRef = useRef(resolvers);
  if (resolversRef.current.length !== resolvers.length) {
    throw new Error("Cannot change the number of resolvers");
  }

  const alerts: WorkspaceAlert[] = [];
  for (const resolver of resolvers) {
    alerts.push(...resolver());
  }

  return alerts;
}
