import React from "react";

import classNames from "@/helpers/classNames";

import Badge, { BadgeVariant } from "../Badge_Legacy";
import Tooltip from "../Tooltip";

type StatusLEDProps = React.PropsWithChildren<{
  variant: React.ComponentProps<typeof Badge>["variant"];
  pulse?: boolean;
}>;

export function StatusLED(props: StatusLEDProps) {
  const clx = useColorClasses(props);
  return (
    <Tooltip content={props.children ? <span>{props.children}</span> : null}>
      <span
        className={classNames(
          "block h-2 w-2 flex-none rounded-full",
          props.pulse && "animate-pulse",
          clx,
        )}
      />
    </Tooltip>
  );
}

function useColorClasses({ variant }: StatusLEDProps) {
  switch (variant) {
    case BadgeVariant.Default: {
      return "bg-blue-400";
    }
    case BadgeVariant.Error: {
      return "bg-red-300/75 dark:bg-red-400/75";
    }
    case BadgeVariant.Info: {
      return "bg-yellow-500/50 dark:bg-yellow-600/75";
    }
    case BadgeVariant.Success: {
      return "bg-green-300 dark:bg-green-400/75";
    }
    default: {
      return "bg-gray-200 dark:bg-gray-600";
    }
  }
}
