import { DevStatus } from "@/apollo/types";
import { Badge } from "@/components/elements/Badge";
import { List } from "@/components/elements/List";
import { Heading, TextMuted } from "@/components/elements/Typography";
import { useDataSourceSlideOver } from "@/components/modules/new-data-source-slideover";
import {
  useComingSoonConnectorOptions,
  useConnectorOptions,
} from "@/features/connectors";
import cn from "@/helpers/classNames";
import { IntegrationLogo, IntegrationLogoBox } from "@/integrations";
import { useDataWarehouseContext } from "@/providers/DataWarehouseProvider";
import { Connectors, SearchFilter } from "@/widgets/connectors";

export function EltSyncsEmptyState() {
  const { onOpen } = useDataSourceSlideOver();

  // const [sortOption, setSortOption] = useState<"popular" | "alphabetical">(
  //   "popular",
  // );

  // const [referenceElement, setReferenceElement] =
  //   useState<HTMLButtonElement | null>(null);
  // const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
  //   null,
  // );
  // const { styles, attributes } = usePopper(referenceElement, popperElement, {
  //   strategy: "absolute",
  //   placement: "bottom-end",
  //   modifiers: [
  //     { name: "preventOverflow", enabled: true },
  //     { name: "offset", options: { offset: [0, 2] } },
  //   ],
  // });

  const dwh = useDataWarehouseContext();
  const options = useConnectorOptions({
    dwIntegration: dwh.integration,
  });
  const betaConnectorOptions = useComingSoonConnectorOptions(dwh.integration);
  return (
    <div className="flex h-full w-full grow flex-col items-center gap-8 overflow-hidden">
      <div className="text-center">
        <Heading className="mb-4 text-2xl">
          Get Started by Connecting Your First Data Source
        </Heading>
        <TextMuted as="div" className="leading-5">
          <span>Start synchronizing your data to </span>
          <IntegrationLogo
            id={dwh.integration.id}
            className="mr-1 inline h-5 w-5 align-bottom"
          />
          <DataWarehouseText integration={dwh.integration} />{" "}
          <span>by connecting a data source.</span>
        </TextMuted>
      </div>
      <div className="flex w-full flex-1 flex-col gap-6 overflow-hidden px-1">
        <div className="flex flex-col items-center overflow-hidden">
          <div className="flex gap-12"></div>
          <Connectors
            options={options}
            // sortOption={sortOption}
            enableSelectExistingConnection
            abilityFilter="EltSourceConnection"
            onSelectIntegration={(option) =>
              onOpen({ integrationId: option.integration.id })
            }
          >
            {({ options, onSelectIntegration }) => (
              <div className="flex w-full max-w-96 flex-col gap-2 overflow-hidden p-1">
                <SearchFilter
                  autoFocus
                  className="rounded-sm"
                  placeholder="Search all data sources..."
                />
                {/* <div className="flex w-full items-center justify-end">
                  <Menu as="div" className="relative">
                    {({ open }) => (
                      <>
                        <TextMuted className="text-xs">Sort by:</TextMuted>
                        <Menu.Button
                          ref={setReferenceElement}
                          as={Button}
                          variant="ghost"
                          size="xs"
                          iconRight={<ChevronDownIcon />}
                          className="ml-0.5 text-xs font-semibold"
                        >
                          {capitalize(sortOption)}
                        </Menu.Button>
                        <Portal>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                          >
                            <Menu.Items
                              ref={setPopperElement}
                              style={styles.popper}
                              {...attributes.popper}
                              className="absolute right-0 w-56 origin-top-right divide-y rounded-md border bg-background shadow-lg ring-1 ring-black/5 focus:outline-none"
                            >
                              <div className="px-1 py-1">
                                <Menu.Item>
                                  {({ active }) => (
                                    <button
                                      onClick={() => setSortOption("popular")}
                                      className={`${
                                        active
                                          ? "bg-gray-200 dark:bg-gray-700"
                                          : "text-gray-900 dark:text-white"
                                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                    >
                                      Popular
                                    </button>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {({ active }) => (
                                    <button
                                      onClick={() =>
                                        setSortOption("alphabetical")
                                      }
                                      className={`${
                                        active
                                          ? "bg-gray-200 dark:bg-gray-700"
                                          : "text-gray-900 dark:text-white"
                                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                    >
                                      Alphabetical
                                    </button>
                                  )}
                                </Menu.Item>
                              </div>
                            </Menu.Items>
                          </Transition>
                        </Portal>
                      </>
                    )}
                  </Menu>
                </div> */}
                <List
                  className={cn(
                    "w-full grow gap-2 divide-y rounded-sm border px-0 py-1 shadow-inner",
                  )}
                >
                  {options.map((option) => {
                    const connectorMetadata = option.metadata?.eltSource;
                    const isSoonConnector = betaConnectorOptions.find(
                      (x) => x.value === option.value,
                    );
                    const isCommunityConnector =
                      option.isCommunity ||
                      !!connectorMetadata?.tags.includes("Community");

                    return (
                      <List.ItemButton
                        key={option.value}
                        className="flex w-full items-center gap-3"
                        onClick={() => {
                          onSelectIntegration(option);
                        }}
                      >
                        <IntegrationLogoBox id={option.value} size="sm" />
                        <div className="flex w-full min-w-0 items-center space-x-2 text-left">
                          <div className="truncate text-sm">
                            {option.metadata?.name ?? option.label}
                          </div>
                          <div className="flex space-x-1">
                            {connectorMetadata?.status === DevStatus.Beta && (
                              <Badge variant="secondary">Beta</Badge>
                            )}
                            {isSoonConnector && (
                              <Badge variant="quarternary">Soon</Badge>
                            )}
                            {isCommunityConnector && (
                              <Badge variant="tertiary">Community</Badge>
                            )}
                            {connectorMetadata?.minRequiredPlan && (
                              <Badge variant="secondary">
                                {connectorMetadata.minRequiredPlan}
                              </Badge>
                            )}
                          </div>
                        </div>
                      </List.ItemButton>
                    );
                  })}
                </List>
              </div>
            )}
          </Connectors>
        </div>
      </div>
    </div>
  );
}

function DataWarehouseText<T extends { id: string; name: string }>({
  integration,
}: {
  integration: T;
}) {
  const { id, name } = integration;
  if (id === "weld-biquery" || id === "bigquery") {
    return <span>BigQuery</span>;
  }
  if (id === "snowflake") {
    return <span>Snowflake</span>;
  }
  if (id === "postgresql") {
    return <span>PostgreSQL</span>;
  }
  if (id === "redshift") {
    return <span>Amazon Redshift</span>;
  }
  return <span>{name}</span>;
}
