import { AuthFeatureGuard } from "@/components/elements/AuthFeatureGuard";
import { IconButton } from "@/components/elements/Button";
import {
  NavButton,
  NavDivider,
  Sidebar,
  useSidebar,
} from "@/components/elements/Sidebar";
import Tooltip from "@/components/elements/Tooltip";
import OrchestrateIcon from "@/components/icons/OrchestrateIcon";
import {
  AiAssistantIcon,
  AlertsIcon,
  DataSourceIcon,
  DestinationIcon,
  EditorIcon,
  HelpIcon,
  ReverseEtlIcon,
  SettingsIcon,
} from "@/components/icons/outline";
import { CurrentUserAvatar } from "@/components/modules/CurrentUserAvatar";
import ButtonPrimitive from "@/components/primitives/Button";
import { useSubscriptionStatus } from "@/features/subscription";
import { default as classNames } from "@/helpers/classNames";
import { useIntercom } from "@/hooks/useIntercom";
import { useAssistantSidebar } from "@/pages/ModelTool/model-generator/assistant";
import { useDataWarehouseContext } from "@/providers/DataWarehouseProvider";
import { useCurrentUser } from "@/providers/UserProvider";
import { Popover, Transition } from "@headlessui/react";
import { ArrowRightIcon, ChevronLeftIcon } from "@heroicons/react/24/outline";
import { useFlags } from "launchdarkly-react-client-sdk";
import React from "react";
import { createPortal } from "react-dom";
import { usePopper } from "react-popper";
import { LinkWithSlug, useNavigateWithSlug } from "routes";
import { useCurrentTabLink } from "../ModelTabs";
import { WorkspaceSwitcher } from "../WorkspaceSwitcher";
import SidebarNotifications, {
  useSidebarNotifications,
} from "../notifications/SidebarNotifications";
import OnboardingChecklistButton from "./OnboardingChecklistButton";
import { TrialBox } from "./TrialBox";
import dayjs from "dayjs";

export function WorkspaceSidebar({
  children,
  ...sidebarProps
}: Omit<React.ComponentProps<typeof Sidebar>, "children"> & {
  children?: React.ReactNode;
}) {
  return (
    <Sidebar {...sidebarProps}>
      <div className="flex h-full flex-col border-r border-gray-200 bg-gray-50 dark:border-gray-700 dark:bg-gray-800">
        <div className="relative h-full overflow-y-auto p-2">
          <WorkspaceSidebarContent />
        </div>
        <div className="flex flex-col px-2 pb-2">
          <NavDivider className="mt-0" />
          <SidebarCollapseButton />
        </div>
      </div>
    </Sidebar>
  );
}

function showTrialBox(
  trial: ReturnType<typeof useSubscriptionStatus>["trial"],
): trial is NonNullable<typeof trial> {
  if (!trial || trial.isExpired) return false;
  return dayjs(trial.trialEndDate).diff(dayjs(), "day") <= 5;
}

export function WorkspaceSidebarContent() {
  const modelToolTabLink = useCurrentTabLink();
  const navigate = useNavigateWithSlug();

  const dwh = useDataWarehouseContext();
  const { trial } = useSubscriptionStatus();
  const { onToggle: onToggleAssistantSidebar } = useAssistantSidebar();
  const showTrial = showTrialBox(trial);
  return (
    <div className="flex h-full w-full flex-col gap-1">
      <div className="flex flex-col gap-1">
        {dwh.features.isExtractEnabled && (
          <NavLink to={"/sources"} icon={<DataSourceIcon />}>
            Data Sources
          </NavLink>
        )}
        {dwh.features.isActivateEnabled && (
          <NavLink to={"/reverse-etl"} icon={<ReverseEtlIcon />}>
            Reverse ETL
          </NavLink>
        )}
        <NavDivider />

        {dwh.features.isTransformEnabled && (
          <>
            <NavLink to={modelToolTabLink} icon={<EditorIcon />}>
              Transform
            </NavLink>
            <NavButton
              onClick={() => {
                navigate({
                  to: modelToolTabLink,
                });
                onToggleAssistantSidebar();
              }}
              icon={<AiAssistantIcon />}
            >
              AI Assistant
            </NavButton>
          </>
        )}
        <NavLink to={"/orchestration"} icon={<OrchestrateIcon />}>
          Orchestration
        </NavLink>
        <NavDivider />
        <AuthFeatureGuard permissions={["manage:all", "read:User"]}>
          <NavLink to={"/settings"} icon={<SettingsIcon />}>
            Settings
          </NavLink>
        </AuthFeatureGuard>
        {showTrial && <TrialBox {...trial} />}
      </div>
      <div className="mb-1 mt-auto flex flex-col gap-1">
        <OnboardingChecklistButton />
        <SidebarNotifications>
          <NotificationNavButton />
        </SidebarNotifications>
        <SidebarHelpDialog>
          <NavButton icon={<HelpIcon />}>Help</NavButton>
        </SidebarHelpDialog>
        <NavDivider />
        <UserProfileLink />
        <NavDivider />
        <WorkspaceSwitcher allowCreateDestination>
          {(currentAccount) => (
            <ButtonPrimitive className="group flex w-full items-center gap-3 transition-all hover:opacity-70 active:opacity-70 dark:hover:opacity-70 dark:active:opacity-70">
              <div className="flex h-9 w-9 shrink-0 items-center justify-center rounded-md bg-black/10 dark:bg-white/10">
                <DestinationIcon className="h-5 w-5" />
              </div>
              <div className="flex h-full flex-1 items-center overflow-hidden">
                <div className="flex w-full flex-col pr-2 text-left">
                  <div className="truncate text-sm dark:text-white">
                    Destinations
                  </div>
                  {currentAccount && (
                    <span className="truncate text-xs text-gray-400 dark:text-gray-400">
                      {currentAccount.name} -{" "}
                      {currentAccount.dataWarehouse?.title}{" "}
                      {currentAccount.dataWarehouse?.subTitle &&
                        `(${currentAccount.dataWarehouse?.subTitle})`}
                    </span>
                  )}
                </div>
              </div>
            </ButtonPrimitive>
          )}
        </WorkspaceSwitcher>
      </div>
    </div>
  );
}

function SidebarCollapseButton() {
  const { isCollapsed, toggleCollapseState } = useSidebar();
  return (
    <IconButton
      icon={
        <ChevronLeftIcon
          className={classNames("transition-all", isCollapsed && "rotate-180")}
        />
      }
      onClick={() => toggleCollapseState()}
      variant="ghost"
      size="sm"
      className={classNames("self-end rounded", isCollapsed && "mr-0.5")}
    />
  );
}

function NotificationNavButton() {
  const { notificationsCount, toggleSidebar } = useSidebarNotifications();
  const hasNotifications = notificationsCount > 0;
  const notificationsCountFormatted =
    notificationsCount > 9 ? "9+" : notificationsCount;
  return (
    <NavButton
      icon={
        <div className="relative">
          <AlertsIcon />
          {hasNotifications && (
            <div className="absolute -right-[7px] -top-[8px] flex h-4 min-w-[1rem] items-center justify-center rounded bg-red-500 px-0.5 text-xs font-medium text-white">
              {notificationsCountFormatted}
            </div>
          )}
        </div>
      }
      onClick={() => toggleSidebar()}
    >
      {hasNotifications ? "View alerts" : "No sync alerts"}
    </NavButton>
  );
}

function NavLink(
  props: { to: string } & React.ComponentProps<
    typeof NavButton<typeof LinkWithSlug>
  >,
) {
  return (
    <NavButton
      as={LinkWithSlug}
      {...props}
      getActiveProps={() => ({
        className: "text-blue-500 bg-black/10 dark:bg-white/10",
      })}
    />
  );
}

const SidebarHelpDialog = (props: { children: React.ReactNode }) => {
  const { intercomChat } = useFlags();

  const popperElRef = React.useRef(null);
  let [referenceElement, setReferenceElement] =
    React.useState<HTMLButtonElement | null>();
  let [popperElement, setPopperElement] =
    React.useState<HTMLDivElement | null>();
  let { styles, attributes } = usePopper(referenceElement, popperElement, {
    strategy: "absolute",
    placement: "auto",
    modifiers: [
      { name: "preventOverflow", enabled: true },
      { name: "offset", options: { offset: [0, 12] } },
    ],
  });

  const Trigger = React.Children.only(props.children);
  return (
    <Popover>
      <Popover.Button as="div">
        {React.isValidElement<any>(Trigger)
          ? React.cloneElement(Trigger, {
              ref: setReferenceElement,
              role: "button",
            })
          : null}
      </Popover.Button>
      {createPortal(
        <div
          ref={popperElRef}
          style={styles.popper}
          {...attributes.popper}
          className="relative z-50 shadow-lg"
        >
          <Transition
            enter="transition duration-100 ease-out"
            enterFrom="scale-95 opacity-0"
            enterTo="scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="scale-100 opacity-100"
            leaveTo="scale-95 opacity-0"
            beforeEnter={() => setPopperElement(popperElRef.current)}
            afterLeave={() => setPopperElement(null)}
          >
            <Popover.Panel className="flex w-72 flex-col items-start justify-start space-y-2 rounded-t-sm border bg-gray-100 p-4 text-left text-sm text-gray-800 dark:border-gray-800 dark:bg-gray-700 dark:text-gray-200">
              <SidebarHelpItem
                as="a"
                href="https://weld.app/docs"
                target="_blank"
                rel="noreferrer"
                icon={
                  <ArrowRightIcon className="h-4 w-4 -rotate-45 transform" />
                }
              >
                Docs
              </SidebarHelpItem>
              <SidebarHelpItem
                as="a"
                href="https://weld.app/learn-sql"
                target="_blank"
                rel="noreferrer"
                icon={
                  <ArrowRightIcon className="h-4 w-4 -rotate-45 transform" />
                }
              >
                Learn SQL
              </SidebarHelpItem>
              {intercomChat && <IntercomChatHelpItem />}
            </Popover.Panel>
          </Transition>
        </div>,
        document.body,
      )}
    </Popover>
  );
};

const IntercomChatHelpItem = () => {
  const intercom = useIntercom();
  return (
    <Tooltip content={"Chat with us"}>
      <div className="w-full">
        <SidebarHelpItem
          onClick={() => intercom.show()}
          icon={<HelpIcon className="h-4 w-4" />}
        >
          Chat
        </SidebarHelpItem>
      </div>
    </Tooltip>
  );
};

type AsProp<EType extends React.ElementType> = {
  as?: EType;
};

type SidebarHelpItemProps<EType extends React.ElementType> = {
  icon?: React.ReactElement;
} & React.PropsWithChildren<AsProp<EType>> &
  React.ComponentPropsWithoutRef<EType>;

const SidebarHelpItem = <EType extends React.ElementType = "button">({
  as,
  children,
  icon,
  ...restProps
}: SidebarHelpItemProps<EType>) => {
  const Component = as || "button";
  return (
    <Component
      className="flex w-full cursor-pointer items-center justify-between rounded-sm px-2 py-2 font-semibold hover:bg-gray-300 dark:hover:bg-gray-600"
      {...restProps}
    >
      {children}
      {icon !== undefined && icon}
    </Component>
  );
};

function UserProfileLink() {
  const user = useCurrentUser();
  return (
    <LinkWithSlug
      className="group flex w-full items-center gap-3 rounded-md transition-all hover:opacity-70 active:opacity-70 dark:hover:opacity-70 dark:active:opacity-70"
      to="/settings/user-profile"
    >
      <CurrentUserAvatar size="lg" className="group-hover:bg-gray-400/50" />
      <div className="flex h-full flex-1 items-center overflow-hidden">
        <div className="flex w-full flex-col pr-2 text-left">
          <span className="max-w-full truncate text-xs font-medium">
            {user.firstName ? (
              <span className="text-gray-700 dark:text-gray-100">
                {user.firstName} {user.lastName || ""}
              </span>
            ) : (
              <span className="text-danger">Click to add your name</span>
            )}
          </span>
          <span className="truncate text-xs text-gray-400 dark:text-gray-400">
            {user.email}
          </span>
        </div>
      </div>
    </LinkWithSlug>
  );
}
