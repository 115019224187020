import { useMemo, useState } from "react";
import { CellProps, Column } from "react-table";
import { LinkWithSlug } from "routes";

import { Notification, NotificationEntityType } from "@/apollo/types";
import { Button, ButtonGroup, IconButton } from "@/components/elements/Button";
import DefaultTable from "@/components/elements/DefaultTable";
import { FormatRelativeTime } from "@/components/elements/FormatRelativeTime";
import { FormatDate } from "@/components/elements/FormatTime";
import Tooltip from "@/components/elements/Tooltip";
import { TextMuted } from "@/components/elements/Typography";
import { NotificationIntegrationIcon } from "@/components/modules/notifications/NotificationIntegrationIcon";
import cn from "@/helpers/classNames";
import { useIsInBackOffice } from "@/pages/BackOffice/useIsInBackOffice";
import { useToast } from "@/providers/ToastProvider";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import {
  ArrowPathIcon,
  CheckIcon,
  Cog6ToothIcon,
} from "@heroicons/react/24/outline";

import { FilteredNotifications } from "../entityNotifications";
import { useNotificationAction } from "../hooks/useNotificationAction";
import { useRetryNotification } from "../hooks/useRetryNotification";
import { NotificationDetailModal } from "./NotificationDetailModal";

export function CollapsibleAlertTable(props: {
  notifications: FilteredNotifications;
  triggerText?: React.ReactNode;
}) {
  const [expanded, setExpanded] = useState(false);
  if (props.notifications.count === 0) {
    return null;
  }
  return (
    <div className="rounded border border-red-400 p-1">
      <Button
        size="sm"
        variant="ghost"
        colorScheme="danger"
        icon={
          <ChevronRightIcon
            className={cn("h-5 w-5 transition-transform", {
              "rotate-90": expanded,
            })}
          />
        }
        onClick={() => setExpanded(!expanded)}
        className="flex w-full justify-start px-1 text-sm text-red-500 focus:ring-0"
      >
        <div className="font-normal">
          <span className="font-semibold">
            {props.triggerText ?? `${props.notifications.count} Errors`}
          </span>
        </div>
      </Button>
      {expanded && (
        <div className="px-2">
          <AlertTable notifications={props.notifications} />
        </div>
      )}
    </div>
  );
}

function AlertTable(props: { notifications: FilteredNotifications }) {
  const notifications = useMemo(
    () => [
      ...props.notifications.account,
      ...props.notifications.connector,
      ...props.notifications.sync,
      ...props.notifications.eltStream,
    ],
    [props.notifications],
  );
  const columns = useMemo<Column<Notification>[]>(() => {
    return [
      {
        accessor: "id",
        Header: <span className="px-2">Name</span>,
        width: 1,
        Cell: ({ row }) => {
          return (
            <div className="flex items-center gap-2 px-2">
              <NotificationIntegrationIcon
                notification={row.original}
                size="xs"
              />
              <div>{row.original.entityName}</div>
            </div>
          );
        },
      },
      {
        id: "error-type",
        Header: "Error Type",
        width: 1,
        Cell: (cellProps: CellProps<Notification>) => {
          return (
            <div>{formatEntityType(cellProps.row.original.entityType)}</div>
          );
        },
      },
      {
        Header: "Issue",
        accessor: "message",
        width: 500,
        Cell: ({ value }) => {
          return <div className="line-clamp-2 whitespace-normal">{value}</div>;
        },
      },
      {
        Header: "Date",
        accessor: "lastOccurence",
        width: 1,
        Cell: ({ value }) => {
          return (
            <div className="whitespace-nowrap">
              <FormatRelativeTime date={value} className="block" />
              <TextMuted as="div" className="text-xs">
                <FormatDate date={value} format="LLL" />
              </TextMuted>
            </div>
          );
        },
      },
      {
        id: "actions",
        Header: "",
        width: 1,
        Cell: (cellProps: CellProps<Notification>) => {
          return (
            <ButtonGroup
              size="sm"
              variant="outline"
              className="flex items-center gap-2"
            >
              <NotificationActionButtons
                notification={cellProps.row.original}
              />
            </ButtonGroup>
          );
        },
      },
    ];
  }, []);

  const [selectedNotification, setSelectedNotification] =
    useState<Notification>();

  return (
    <>
      <DefaultTable
        data={notifications}
        columns={columns}
        onRowClick={(notification) => setSelectedNotification(notification)}
      />
      {selectedNotification?.messageData ? (
        <NotificationDetailModal
          notification={selectedNotification}
          isOpen={!!selectedNotification}
          onClose={() => setSelectedNotification(undefined)}
        />
      ) : null}
    </>
  );
}

function formatEntityType(type: NotificationEntityType) {
  switch (type) {
    case NotificationEntityType.Account:
      return "Account";
    case NotificationEntityType.Connector:
      return "Connector";
    case NotificationEntityType.Sync:
      return "Sync";
    case NotificationEntityType.Materialisation:
      return "Materialisation";
    case NotificationEntityType.Stream:
      return "Table";
    case NotificationEntityType.Template:
      return "Template";
    case NotificationEntityType.Workflow:
      return "Workflow";
  }
}

const NotificationActionButtons = (props: { notification: Notification }) => {
  const toast = useToast();
  const { isBackOffice } = useIsInBackOffice();
  const { getActionProps } = useNotificationAction({
    onCompleted: (message) => toast("Success", message, "success"),
    onError: (message) => toast("Error", message, "error"),
  });

  const { retry, loading } = useRetryNotification({
    onCompleted: (message) => toast("Success", message, "success"),
    onError: (message) => toast("Error", message, "error"),
  });

  const actionProps = getActionProps(props.notification);
  return (
    <>
      {props.notification.isDismissable && (
        <Tooltip
          content={
            props.notification.entityType === NotificationEntityType.Account
              ? "Mark as resolved and retry affected syncs"
              : "Retry sync"
          }
        >
          <IconButton
            icon={
              props.notification.entityType ===
              NotificationEntityType.Account ? (
                <CheckIcon />
              ) : (
                <ArrowPathIcon />
              )
            }
            onClick={(e) => {
              if (!loading) {
                e.stopPropagation();
                retry(props.notification);
              }
            }}
          />
        </Tooltip>
      )}
      {!isBackOffice &&
        actionProps.map((action, i) => (
          <Tooltip key={i} content={action.label}>
            <IconButton
              icon={<Cog6ToothIcon />}
              as={LinkWithSlug as any}
              {...{
                to: action.to,
              }}
              onClick={(e) => {
                if (action.handler) {
                  e.preventDefault();
                  e.stopPropagation();
                  action.handler(e);
                }
              }}
            />
          </Tooltip>
        ))}
    </>
  );
};
